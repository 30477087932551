import { storeToRefs, defineStore } from 'pinia';
import { ref, computed } from 'vue';

import { useI18n } from 'vue-i18n';
import { useRouter, useRoute } from 'vue-router';

import { useUserStore } from '@/_store/user.store.js';
import { useAuthStore } from '@/_store/auth.store.js';
import { useContractsStore } from '@/_store/contracts.store.js';

import obolService from '@/_services/obol.service';

import { useRootEvents } from '@/composables/useRootEvents';
import { EVENTS } from '@/consts';

import { useToast } from '@/composables/useToast';
const toast = useToast();

import { OBOL_CODE, TIPO_PERSONA, DOCUMENTS } from '@/consts.js';
import { useAccountsStore } from '@/_store/accounts.store';

export const useUserStatusStore = defineStore('userStatus', () => {
    const router = useRouter();
    const route = useRoute();
    const { t } = useI18n();

    const userStore = useUserStore();
    const { user } = storeToRefs(userStore);

    const authStore = useAuthStore();
    const { isLoggedIn, obolEnabled } = storeToRefs(authStore);

    const rootEvents = useRootEvents();

    const contractsStore = useContractsStore();
    const { contractsAndDocuments } = storeToRefs(contractsStore);

    /**
     * Checks if ficha de cliente is pending.
     */
    const missingFicha = computed(() => {
        if (!user.value.btg) return false;

        return (
            contractsAndDocuments.value.contracts.true.length &&
            contractsAndDocuments.value.contracts.true.findIndex(
                (contract) => contract.codigoSADCO === DOCUMENTS.FICHA_PN
            ) !== -1
        );
    });

    /**
     * Checks if encuesta de perfil is pending.
     */
    const missingEncuestaPerfil = computed(() => {
        if (!user.value.btg) return false;

        return (
            contractsAndDocuments.value.contracts.true.length &&
            contractsAndDocuments.value.contracts.true.findIndex((contract) =>
                ['30', '36', '50'].includes(contract.codigoSADCO)
            ) !== -1
        );
    });

    /**
     * Checks if NCG380 is pending.
     */
    const missingNCG380 = computed(() => {
        if (!user.value.btg) return false;

        return (
            contractsAndDocuments.value.contracts.true.length &&
            contractsAndDocuments.value.contracts.true.findIndex(
                (contract) => contract.codigoSADCO === DOCUMENTS.NCG380
            ) !== -1
        );
    });

    const hasNoAccounts = computed(() => {
        if (!user.value.btg) return false;

        return (
            !user.value.extraData ||
            (Object.keys(user.value.extraData).length === 0 &&
                user.value.extraData.constructor === Object) ||
            !user.value.extraData.cuentas ||
            user.value.extraData.cuentas?.length === 0
        );
    });

    const noBTGObolFailed = computed(() => {
        return (
            !user.value.btg &&
            user.value.onboardingCode === '-1' &&
            !obolEnabled.value
        );
    });

    const isOldMEL = computed(() => {
        return !user.value.btg && user.value.onboardingCode === null;
    });

    const isObolProcessing = computed(() => {
        if (user.value.btg) return false;

        return (
            !user.value.btg &&
            [OBOL_CODE.PROCESO_DE_DISTRIBUCION].includes(
                user.value.onboardingCode
            )
        );
    });

    const isObolWaiting = computed(() => {
        if (user.value.btg) return false;

        return [
            OBOL_CODE.VALIDACION_AML,
            OBOL_CODE.VALIDACION_COMISIONES,
            OBOL_CODE.VALIDACION_RIESGO,
        ].includes(user.value.onboardingCode);
    });

    const isExtranjero = computed(() => {
        if (user.value.btg) return false;

        return [OBOL_CODE.EXTRANJERO].includes(user.value.onboardingCode);
    });

    const isPringadoButTheyCouldDoSomething = computed(() => {
        if (user.value.btg) return false;

        return [
            OBOL_CODE.SINACOFI,
            OBOL_CODE.TOO_MANY_TRIES,
            OBOL_CODE.INVITACION_EN_CURSO,
        ].includes(user.value.onboardingCode);
    });

    const isMinor = computed(() => {
        if (user.value.btg) return false;
        return user.value.onboardingCode === OBOL_CODE.MENOR_DE_EDAD;
    });

    const isPringado = computed(() => {
        if (user.value.btg) return false;
        return user.value.onboardingCode === OBOL_CODE.PRINGADO;
    });

    const isEnterprise = computed(() => {
        if (user.value.btg) return false;
        return (
            user.value.extraData.tipoPersona === TIPO_PERSONA.EMPRESA ||
            user.value.onboardingCode === OBOL_CODE.PERSONA_JURIDICA
        );
    });

    const accountStore = useAccountsStore();
    const { currentAccount } = storeToRefs(accountStore);

    const isBlocked = computed(() => {
        if (!user.value.btg) return false;
        return (
            currentAccount &&
            currentAccount.value.estadoVisible &&
            currentAccount.value.estadoVisible != 1
        );
    });

    const updateAccount = async () => {
        loading.value = true;
        await obolService
            .sendInvitation()
            .then((response) => {
                const code =
                    typeof response.data === 'number'
                        ? String(response.data)
                        : response.data;
                user.value.onboardingCode = code;

                if (
                    ![...Object.values(OBOL_CODE), '-1'].includes(code) &&
                    code !== null
                ) {
                    this.$router.push({ name: 'onboarding' });
                } else {
                    // Here, OBOL failed, so we need to reset /login and /user to show the correct lounge case.
                    window.location.href = '/portfolio';
                }
            })
            .catch(() => {
                toast.toasty({
                    kind: 'error',
                    message: '¡Hubo un error! Por favor contáctanos',
                    duration: 4000,
                });
            })
            .finally(() => {
                loading.value = false;
            });
    };

    const openIntercom = () => {
        rootEvents.emit(EVENTS.INTERCOM_OPEN);
    };

    const loading = ref(false);
    const inverse = computed(() =>
        ['mercados-en-linea-detalle', 'mercados-en-linea'].includes(route.name)
    );
    const rules = computed(() => {
        return {
            isPublic: {
                on: !isLoggedIn.value,
                modal: {
                    title: '¡Sólo estás a un paso!',
                    description:
                        'Para invertir, inicia sesión o crea tu cuenta 100% digital en BTG Pactual.',
                    primaryButtonText: 'Crear cuenta',
                    secondaryButtonText: 'Iniciar sesión',
                    showOneAction: false,
                    showActions: true,
                    action: () => {
                        router.push({ name: 'register' });
                    },
                    secondary: () => {
                        rootEvents.emit(EVENTS.QUICK_LOGIN_OPEN, {
                            inverse: inverse.value,
                        });
                    },
                },
            },
            hasNoAccounts: {
                on: hasNoAccounts.value || noBTGObolFailed.value,
                modal: {
                    title: 'Contáctanos',
                    description:
                        'Hubo un problema cargando tu cuenta. Contáctanos por el chat.',
                    primaryButtonText: 'Ir al chat',
                    showOneAction: true,
                    showActions: false,
                    action: openIntercom,
                },
                drawer: {
                    title: 'Contáctanos',
                    description:
                        'Hubo un problema cargando tu cuenta. Contáctanos por el chat.',
                    prompt: 'Ir al chat',
                },
                hero: {
                    title: t('portafolio.limited.hero.semiPringado.title'),
                    description:
                        'Hubo un problema cargando tu cuenta. Contáctanos por el chat.',
                    action: openIntercom,
                    prompt: t('portafolio.limited.hero.semiPringado.prompt'),
                    backgroundImage: '/images/limited/contact.png',
                    image: '/images/limited/contact.png',
                },
            },
            isObolIncomplete: {
                on: obolEnabled.value,
                modal: {
                    title: 'Ya casi estás',
                    description:
                        'Finaliza tu registro y comienza a invertir 100% digital.',
                    primaryButtonText: 'Aceptar',
                    showOneAction: true,
                    showActions: false,
                    action: () => {
                        router.push({ name: 'onboarding' });
                    },
                },
                drawer: {
                    title: 'Ya casi estás',
                    description:
                        'Finaliza tu registro y comienza a invertir 100% digital.',
                    prompt: 'Finalizar registro',
                },
                hero: {
                    title: t('portafolio.limited.hero.incomplete.title'),
                    description: t(
                        'portafolio.limited.hero.incomplete.subtitle'
                    ),
                    action: () => {
                        router.push({ name: 'onboarding' });
                    },
                    prompt: t('portafolio.limited.hero.incomplete.prompt'),
                    backgroundImage: '/images/limited/almost.png',
                    image: '/images/limited/almost.png',
                },
                // perfilbanner: {
                //     description:
                //         'Para invertir con BTG Pactual, actualiza tu cuenta.',
                //     prompt: 'Actualizar cuenta',
                // },
            },
            isOldMEL: {
                on: isOldMEL.value,
                modal: {
                    title: 'Ya casi estás',
                    description:
                        'Para invertir con BTG Pactual, actualiza tu cuenta.',
                    primaryButtonText: 'Actualizar cuenta',
                    showOneAction: true,
                    showActions: false,
                    action: updateAccount,
                },
                drawer: {
                    title: 'Ya casi estás',
                    description:
                        'Para invertir con BTG Pactual, actualiza tu cuenta.',
                    prompt: 'Actualizar cuenta',
                },
                hero: {
                    title: t('portafolio.limited.hero.oldMEL.title'),
                    description: t('portafolio.limited.hero.oldMEL.subtitle'),
                    action: updateAccount,
                    prompt: t('portafolio.limited.hero.oldMEL.prompt'),
                    backgroundImage: '/images/limited/almost.png',
                    image: '/images/limited/almost.png',
                },
                // perfilbanner: {
                //     description:
                //         'Para invertir con BTG Pactual, actualiza tu cuenta.',
                //     prompt: 'Actualizar cuenta',
                // },
            },
            isExtranjero: {
                on: isExtranjero.value,
                modal: {
                    title: 'Ya casi estás',
                    description: `Finaliza tu registro y comienza a invertir 100% digital.`,
                    primaryButtonText: 'Aceptar',
                    showOneAction: true,
                    showActions: false,
                    action: () => {
                        router.push({ name: 'pre-onboarding' });
                    },
                },
                drawer: {
                    title: 'Ya casi estás',
                    description: `Finaliza tu registro y comienza a invertir 100% digital.`,
                    prompt: 'Finalizar registro',
                },
                hero: {
                    title: t('portafolio.limited.hero.incomplete.title'),
                    description: t(
                        'portafolio.limited.hero.incomplete.subtitle'
                    ),
                    action: () => {
                        router.push({ name: 'pre-onboarding' });
                    },
                    prompt: t('portafolio.limited.hero.incomplete.prompt'),
                    backgroundImage: '/images/limited/almost.png',
                    image: '/images/limited/almost.png',
                },
            },
            isObolProcessing: {
                on: isObolProcessing.value,
                modal: {
                    title: 'Ya casi estás',
                    description:
                        'Estamos finalizando la creación de tu cuenta. En poco tiempo podrás invertir con BTG Pactual.',
                    primaryButtonText: 'Aceptar',
                    showOneAction: true,
                    showActions: false,
                    action: null,
                },
                drawer: {
                    title: 'Ya casi estás',
                    description:
                        'Estamos finalizando la creación de tu cuenta. En poco tiempo podrás invertir en BTG Pactual.',
                    prompt: null,
                },
                hero: {
                    title: t('portafolio.limited.hero.processing.title'),
                    description: t(
                        'portafolio.limited.hero.processing.subtitle'
                    ),
                    action: null,
                    backgroundImage: '/images/limited/waiting.png',
                    image: '/images/limited/waiting.png',
                },
            },
            isObolWaiting: {
                on: isObolWaiting.value,
                modal: {
                    title: 'Ya casi estás',
                    description:
                        'Estamos procesando la creación de tu cuenta. En poco tiempo podrás invertir con BTG Pactual.',
                    primaryButtonText: 'Aceptar',
                    showOneAction: true,
                    showActions: false,
                    action: null,
                },
                drawer: {
                    title: 'Ya casi estás',
                    description:
                        'Estamos procesando la creación de tu cuenta. En poco tiempo podrás invertir con BTG Pactual.',
                    prompt: null,
                },
                hero: {
                    title: t('portafolio.limited.hero.waiting.title'),
                    description: t('portafolio.limited.hero.waiting.subtitle'),
                    action: null,
                    backgroundImage: '/images/limited/waiting.png',
                    image: '/images/limited/waiting.png',
                },
            },
            isPringadoButTheyCouldDoSomething: {
                on: isPringadoButTheyCouldDoSomething.value,
                modal: {
                    title: 'Contáctanos',
                    description:
                        'Hubo un problema en la creación de tu cuenta. Contáctanos por el chat.',
                    primaryButtonText: 'Ir al chat',
                    showOneAction: true,
                    showActions: false,
                    action: openIntercom,
                },
                drawer: {
                    title: 'Contáctanos',
                    description:
                        'Hubo un problema en la creación de tu cuenta. Contáctanos por el chat.',
                    prompt: 'Ir al chat',
                },
                hero: {
                    title: t('portafolio.limited.hero.semiPringado.title'),
                    description: t(
                        'portafolio.limited.hero.semiPringado.subtitle'
                    ),
                    action: openIntercom,
                    prompt: t('portafolio.limited.hero.semiPringado.prompt'),
                    backgroundImage: '/images/limited/contact.png',
                    image: '/images/limited/contact.png',
                },
            },
            isMinor: {
                on: isMinor.value,
                modal: {
                    title: 'Explora otras funciones',
                    description:
                        'Podrás invertir al cumplir 18 años. Por hoy, explora precios, configura alertas personalizadas y crea tu propia lista de favoritos con Mercados en Línea de BTG Pactual.',
                    primaryButtonText: 'Aceptar',
                    showOneAction: true,
                    showActions: false,
                    action: null,
                },
                drawer: {
                    title: 'Acceso limitado',
                    description:
                        'Podrás invertir al cumplir 18 años. Por ahora, puedes utilizar todas las funcionalidades no transaccionales de Mercados en Línea: revisar precios, crear alertas y favoritos, y más.',
                    prompt: null,
                },
                hero: {
                    title: t('portafolio.limited.hero.minor.title'),
                    description: t('portafolio.limited.hero.minor.subtitle'),
                    action: () => {
                        router.push({ name: 'mercados-en-linea' });
                    },
                    icon: '/images/mel_logo_small_inverse.svg',
                    responsiveAction: () => {
                        window.location.href = 'https://onelink.to/989bps';
                    },
                    prompt: t('portafolio.limited.hero.minor.prompt'),
                    responsivePrompt: 'Descargar app',
                    backgroundImage: '/images/limited/blocked.png',
                    image: '/images/limited/blocked.png',
                },
            },
            isPringado: {
                on: isPringado.value,
                modal: {
                    title: 'Explora otras funciones',
                    description:
                        'Por ahora, no puedes invertir con BTG Pactual. Explora otras funciones.',
                    primaryButtonText: 'Aceptar',
                    showOneAction: true,
                    showActions: false,
                    action: null,
                },
                drawer: {
                    title: 'Acceso limitado',
                    description:
                        'Por ahora, no puedes invertir con BTG Pactual. Puedes utilizar todas las funcionalidades no transaccionales de Mercados en Línea: revisar precios, crear alertas y favoritos, y más.',
                    prompt: null,
                },
                hero: {
                    title: t('portafolio.limited.hero.pringado.title'),
                    description: t('portafolio.limited.hero.pringado.subtitle'),
                    action: () => {
                        router.push({ name: 'mercados-en-linea' });
                    },
                    icon: '/images/mel_logo_small_inverse.svg',
                    responsiveAction: () => {
                        window.location.href = 'https://onelink.to/989bps';
                    },
                    prompt: t('portafolio.limited.hero.pringado.prompt'),
                    responsivePrompt: 'Descargar app',
                    backgroundImage: '/images/limited/blocked.png',
                    image: '/images/limited/blocked.png',
                },
            },
            isEnterprise: {
                on: isEnterprise.value,
                modal: {
                    title: 'Contáctanos',
                    description:
                        'Para abrir una cuenta de persona jurídica, escríbenos por el chat.',
                    primaryButtonText: 'Ir al chat',
                    showOneAction: true,
                    showActions: false,
                    action: openIntercom,
                },
                drawer: {
                    title: 'Contáctanos',
                    description:
                        'Para abrir una cuenta de persona jurídica, escríbenos por el chat.',
                    prompt: 'Ir al chat',
                },
                hero: {
                    title: t('portafolio.limited.hero.legalPerson.title'),
                    description: t(
                        'portafolio.limited.hero.legalPerson.subtitle'
                    ),
                    action: openIntercom,
                    prompt: t('portafolio.limited.hero.legalPerson.prompt'),
                    backgroundImage: '/images/limited/contact.png',
                    image: '/images/limited/contact.png',
                },
            },
            missingFicha: {
                on: missingFicha.value,
                modal: {
                    title: 'Ficha de cliente',
                    description:
                        'Por normativa, debes actualizar o confirmar tu información personal.',
                    primaryButtonText: 'Ir a la Ficha',
                    showOneAction: true,
                    showActions: false,
                    action: () => {
                        router.push({ name: 'ficha-cliente' });
                    },
                },
                perfilbanner: {
                    description:
                        'Por normativa, debes actualizar o confirmar tu información personal.',
                    prompt: 'Ir a la Ficha',
                    href: '/ficha',
                },
                showMenu: true,
            },
            missingEncuestaPerfil: {
                on: missingEncuestaPerfil.value,
                modal: {
                    title: 'Encuesta de perfil',
                    description:
                        'Por normativa, debes actualizar o confirmar tu perfil de inversionista.',
                    primaryButtonText: 'Ir a la Encuesta',
                    showOneAction: true,
                    showActions: false,
                    action: () => {
                        router.push({ name: 'profile-investmentsurvey' });
                    },
                },
                perfilbanner: {
                    description:
                        'Por normativa, debes actualizar o confirmar tu perfil de inversionista.',
                    prompt: 'Ir a la Encuesta',
                    href: '/perfil/inversionista/encuesta',
                },
                showMenu: true,
            },
            missingNCG380: {
                on: missingNCG380.value,
                modal: {
                    title: 'Contrato de Prestación de Servicios - NCG 380',
                    description:
                        'Revisa y firma tu Contrato de Prestación de Servicios con BTG Pactual Chile S.A. Corredores de Bolsa.',
                    primaryButtonText: 'Ir al Contrato',
                    showOneAction: true,
                    showActions: false,
                    action: () => {
                        router.push({ name: 'ncg380' });
                    },
                },
                perfilbanner: {
                    description:
                        'Revisa y firma tu Contrato de Prestación de Servicios con BTG Pactual Chile S.A. Corredores de Bolsa.',
                    prompt: 'Ir al Contrato',
                    href: '/ncg380',
                },
                showMenu: true,
            },
            isBlocked: {
                on: isBlocked.value,
                modal: {
                    title: 'Cuenta bloqueada',
                    description:
                        'Tu cuenta está bloqueada. Para más información, contáctanos por el chat.',
                    primaryButtonText: 'Ir al chat',
                    showOneAction: true,
                    showActions: false,
                    action: openIntercom,
                },
                showMenu: true,
            },
        };
    });

    const loungeCase = computed(() => {
        let result = null;
        Object.keys(rules.value).some((rule) => {
            if (rules.value[rule].on) result = rules.value[rule];
            return rules.value[rule].on;
        });
        return result;
    });

    return {
        rules,
        loading,
        loungeCase,
        missingFicha,
        missingEncuestaPerfil,
        missingNCG380,
        isBlocked,
    };
});
