<template>
    <OrqDrawerBody>
        <template #header>
            <TransactionalHeader
                :inverse="inverse"
                :title="title"
                :subtitle="subtitle"
            />
        </template>

        <template #body>
            <OrqColumn :gap="48">
                <BodyAmount
                    :amount="balance"
                    :currency="item?.config?.currency"
                    :inverse="inverse"
                />
                <SelectInvestment
                    :options="priceOptions"
                    :label="t('transactions.price')"
                    :inverse="inverse"
                    @selected="priceSelectHandler"
                >
                    <div
                        v-if="selectedPrice === PRICE_TYPES.PEAK"
                        class="price-label body-baseline"
                        :class="{
                            'text--emphasis-base-high': !inverse,
                            'text--emphasis-base-high-inverse': inverse,
                        }"
                    >
                        {{ t('transactions.peakLabel') }}
                        <OrqAmount
                            :amount="cuotaPrice"
                            :format="
                                item?.config?.currency === 'CLP'
                                    ? 'CLPEXT'
                                    : item?.config?.currency
                            "
                        />
                        <OrqTooltip
                            :inverse="inverse"
                            :label="t('transactions.peakTooltip')"
                        >
                            <OrqIconContainer name="info" :size="13" />
                        </OrqTooltip>
                    </div>
                    <LimitPrice
                        v-if="selectedPrice === PRICE_TYPES.LIMIT"
                        v-model.number="order.price"
                        :available-balance="balance"
                        :currency="
                            item?.config?.currency === 'CLP'
                                ? 'CLPEXT'
                                : item?.config?.currency
                        "
                        :error="priceError"
                        :valid-multiple="
                            v$.order.price?.multipleOfValue?.$message
                        "
                        :validator="v$.order.price.$errors"
                        :item="item"
                        :last-price="limitPrice"
                        :min-price="minPrice"
                        :max-price="maxPrice"
                        :ins-price="item?.insPrice"
                        :inverse="inverse"
                        @update:model-value="debouncedGetCommission()"
                    />
                </SelectInvestment>

                <SelectInvestment
                    :options="investOptions"
                    :label="t('transactions.investBy')"
                    :inverse="inverse"
                    @selected="investSelectHandler"
                >
                    <BodyInvestAmount
                        v-if="selectedInvest === INVEST_TYPES.AMOUNT"
                        v-model.number="inputAmount"
                        :available-balance="balance"
                        :currency="item?.config?.currency"
                        hide-label
                        :error-msg="amountErrorMsg"
                        :inverse="inverse"
                        :disabled="checkPriceError"
                        @update:model-value="debouncedGetCommission(true)"
                    />
                    <BodyInvestAmount
                        v-if="selectedInvest === INVEST_TYPES.QUANTITY"
                        v-model.number="inputQuantity"
                        currency="PLAIN"
                        hide-label
                        hide-button
                        :error-msg="amountErrorMsg"
                        :inverse="inverse"
                        :disabled="checkPriceError"
                        @update:model-value="debouncedGetCommission(true)"
                    />
                </SelectInvestment>

                <OrqNotificationFixed
                    v-if="checkPriceError"
                    notification-type="fixed"
                    :label="t('transactions.higherThanLimit')"
                    kind="info"
                    :label-size="12"
                />

                <OrqNotificationFixed
                    v-if="quotaError && order.commission"
                    notification-type="fixed"
                    kind="info"
                    :label-size="12"
                    :label="
                        t('transactions.insufficientAmountInfo', {
                            name: title,
                            measure: 'cuota',
                            quota: formatCurrency(
                                order.price,
                                item?.config?.currency
                            ),
                            commission: formatCurrency(
                                order.commission + order.iva,
                                item?.config?.currency
                            ),
                        })
                    "
                />

                <OrqNotificationFixed
                    v-if="maxInvError && balance >= order.amount"
                    notification-type="fixed"
                    kind="info"
                    :label-size="12"
                    :label="
                        t('transactions.surpassMaxInfo', {
                            max: formatCurrency(
                                item?.config?.invMaxAmount,
                                item?.config?.currency
                            ),
                        })
                    "
                />
                <BodyInfo :data="investInfo" :inverse="inverse" />
                <div
                    v-if="props.item?.config?.mirrorIns"
                    class="switch-container"
                >
                    <div class="switch-label">
                        <span
                            class="body-baseline"
                            :class="`text--emphasis-base-high${
                                inverse ? '-inverse' : ''
                            }`"
                            >{{ t('transactions.secondaryMarket') }}</span
                        >

                        <OrqTooltip
                            :inverse="inverse"
                            :label="t('transactions.secondaryMarketTooltip')"
                        >
                            <OrqIconContainer
                                name="info"
                                :size="13"
                                :color="`emphasis-base-high${
                                    inverse ? '-inverse' : ''
                                }`"
                            />
                        </OrqTooltip>
                    </div>
                    <OrqSwitcher
                        :checked="switcher"
                        :inverse="inverse"
                        @change="switchMirror"
                    />
                </div>
            </OrqColumn>
        </template>

        <template #footer>
            <TransactionalFooter
                :steps="totalSteps"
                :next-enabled="isValid && !commissionLoading"
                :loading="commissionLoading"
                :inverse="inverse"
                :disabled="inputAmount <= 0 && inputQuantity <= 0"
                @next="nextStep()"
            />
        </template>
    </OrqDrawerBody>
</template>

<script setup>
import { computed, ref } from 'vue';
import { debounce } from 'lodash';

import TransactionalHeader from '../TransactionalHeader.vue';
import TransactionalFooter from '../TransactionalFooter.vue';
import BodyInvestAmount from '../body-components/BodyInvestAmount.vue';
import LimitPrice from '@/components/transactional-funds/Shared/LimitPrice.vue';
import SelectInvestment from '../Shared/SelectInvestment.vue';
import BodyInfo from '../body-components/BodyInfo.vue';
import BodyAmount from '../body-components/BodyAmount.vue';
import {
    OrqDrawerBody,
    OrqColumn,
    OrqAmount,
    OrqTooltip,
    OrqNotificationFixed,
    OrqIconContainer,
    OrqSwitcher,
} from '@digital/orquestra';
import {
    TYPES_DATA,
    PRICE_TYPES,
    INVEST_TYPES,
    PRICES,
    OPERATION,
    MARKET,
    EVENTS,
    TAGS,
    ARCHITECTURE,
    TYPES,
} from '@/consts';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { useVuelidate } from '@vuelidate/core';
import {
    overZero,
    nonZero,
    minValue,
    maxValue,
    multipleOfValue,
} from '@/_helpers/rules.helper.js';
import { formatCurrency, removeFormat } from '@/directives/currencyFormat';

import { useRootEvents } from '@/composables/useRootEvents';
const rootEvents = useRootEvents();

import { useOrderStore } from '@/_store/order.store.js';
const orderStore = useOrderStore();
const { order, totalSteps, commissionLoading } = storeToRefs(orderStore);
const {
    initOrder,
    nextStep,
    getCommissionByAmount,
    getCommissionByQuantity,
    cancelCommission,
} = orderStore;

const props = defineProps({
    item: {
        type: Object,
        default: () => ({}),
    },
    balance: {
        type: Number,
        default: 0,
    },
    inverse: {
        type: Boolean,
        default: false,
    },
});

const limitPrice = ref(props.item?.insPrice?.price);
const priceOptions = ref(['peak', 'limit']);
const selectedPrice = ref(0);

const cuotaPrice = computed(() => {
    return props.item?.insPrice?.sellPrice ?? props.item?.insPrice?.price;
});

const priceSelectHandler = (selection) => {
    if (selection === 0) {
        order.value.price = cuotaPrice.value;
    } else {
        v$.value?.order?.price?.$reset();
        order.value.price = null;
    }
    selectedPrice.value = selection;
    order.value.selectedPrice = priceOptions.value[selection];
};

const price = computed(() => {
    return order.value.price;
});

// Puntas
const priceRange = computed(
    () => (limitPrice.value * PRICES.LIMIT_PERCENT) / 100
);
const minPrice = computed(() => limitPrice.value - priceRange.value);
const maxPrice = computed(() => limitPrice.value + priceRange.value);

// Type: Amount or quantity
const investOptions = ['amount', 'quantity'];
const selectedInvest = ref(INVEST_TYPES.AMOUNT);
const investSelectHandler = (selection) => {
    selectedInvest.value = selection;
    inputAmount.value = null;
    inputQuantity.value = null;

    v$.value?.order?.$reset();
    order.value.amount = null;
    order.value.quantity = null;
};

const inputAmount = ref(null);
const inputQuantity = ref(null);

const debouncedGetCommission = debounce((touchAmount = false) => {
    v$.value.order?.price?.$touch();
    order.value.amount = removeFormat(inputAmount.value);
    cancelCommission();

    if (touchAmount) {
        v$.value.order?.amount?.$touch();
    }

    if (!inputAmount.value && !inputQuantity.value) {
        return;
    }

    if (inputAmount.value > props.balance) {
        return;
    }

    // Preventing calls when price is not valid
    if (!order.value.price) {
        return;
    }

    if (selectedInvest.value === INVEST_TYPES.AMOUNT) {
        getCommissionByAmount({
            amount: removeFormat(inputAmount.value),
        });
        return;
    }
    if (selectedInvest.value === INVEST_TYPES.QUANTITY) {
        getCommissionByQuantity({
            quantity: removeFormat(inputQuantity.value),
        });
    }
}, 500);

const { t } = useI18n();

const title = computed(() => {
    return props.item?.info?.name;
});

const subtitle = computed(() => {
    return TYPES_DATA[props.item?.info?.type?.id]?.nameType ?? '';
});

const isValid = computed(() => {
    if (checkPriceError.value) {
        return false;
    }
    return v$.value.order.$anyDirty && !v$.value.order.$invalid;
});

const maxRemuneration = computed(() => {
    const remunerationStr = props.item?.config?.remuneration ?? '';
    const numberMatch = remunerationStr.match(/\d+,\d+/);
    if (numberMatch) {
        return Number(numberMatch[0].replace(/,/g, '.'));
    }
    return 0;
});

const investInfo = computed(() => {
    const data = [];
    data.push({
        title: t('transactions.minInvestment'),
        value: {
            amount: props.item?.config?.invMinAmount,
            format: props.item?.config?.currency,
        },
    });
    data.push({
        title: t('transactions.maxRemuneration'),
        value: {
            amount: maxRemuneration.value,
            format: 'PERCENT',
        },
    });
    if (props.item?.config?.retiroAnticipado) {
        data.push({
            title: t('transactions.earlyRescueCommission'),
            value: {
                label: props.item?.config?.comisionRescate || '-',
            },
        });
    }
    return data;
});

const isBolsaETF = computed(
    () =>
        props.item?.config?.tags.includes(TAGS.ETF) &&
        props.item?.architecture === ARCHITECTURE.BOLSA &&
        [TYPES.FONDO_MUTUO, TYPES.FONDO_DE_INVERSION].includes(
            props.item?.info?.type?.id
        )
);

const switcher = ref(true);
const switchMirror = () => {
    rootEvents.emit(EVENTS.TRANSACCIONAL_OPEN, {
        idEncoded: props?.item?.mirrorInsEncoded,
        operation: order.value.operation,
        inverse: props.inverse,
    });
};
const rules = computed(() => {
    const amountRules = {
        balanceOverZero: overZero(props.balance),
        nonZero: nonZero(),
        lessThanMaxInv: maxValue(
            props.item?.config?.invMaxAmount,
            t('transactions.surpassMax')
        ),
        lessThanBalance: maxValue(props.balance),
        moreThanMin: minValue(props.item?.config?.invMinAmount),
        moreThanQuota: minValue(
            Math.floor(price.value),
            t('transactions.insufficientAmount')
        ),
        moreThanQuotaPlusCommission: minValue(
            Math.floor(price.value + order.value.commission + order.value.iva),
            t('transactions.insufficientAmount')
        ),
    };
    const priceRules = {
        nonZero: nonZero(),
        lessThanMax: maxValue(maxPrice.value),
        moreThanMin: minValue(minPrice.value),
        multipleOfValue: isBolsaETF.value ? multipleOfValue(price.value) : true,
    };

    return {
        order: {
            amount: amountRules,
            price: selectedPrice.value === PRICE_TYPES.LIMIT ? priceRules : {},
        },
    };
});

const v$ = useVuelidate(rules, { order });

const amountErrorMsg = computed(
    () => v$.value.order.amount?.$errors[0]?.$message ?? null
);

const quotaError = computed(
    () =>
        selectedInvest.value === INVEST_TYPES.AMOUNT &&
        v$.value.order.amount?.$errors.find(
            (error) => error?.$validator === 'moreThanQuotaPlusCommission'
        )
);

const maxInvError = computed(
    () =>
        selectedInvest.value === INVEST_TYPES.AMOUNT &&
        v$.value.order.amount?.$errors.find(
            (error) => error?.$validator === 'lessThanMaxInv'
        )
);

const priceError = computed(() => v$.value.order.price?.$error ?? false);

const currency = computed(() => {
    return !props.item?.config?.multiCurrency &&
        props.item?.config?.currency === 'USD'
        ? 'USD'
        : 'CLP';
});

const checkPriceError = computed(() => {
    if (selectedPrice.value !== PRICE_TYPES.PEAK) {
        return false;
    }
    return (
        cuotaPrice.value < minPrice.value || cuotaPrice.value > maxPrice.value
    );
});

initOrder({
    idEncoded: props.item?.idEncoded,
    commission: 0,
    operation: OPERATION.BUY,
    price: cuotaPrice.value,
    market: MARKET.BOLSA,
    investType: 'quantity',
    currency: currency.value,
});
</script>

<style lang="scss" scoped>
.price-label {
    display: flex;
    flex-direction: row;
    gap: 4px;
}
.switch-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.switch-label {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
}
</style>
