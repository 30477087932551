import api from './api.js';

export default {
    getPortfolioByAccount({ account }) {
        if (!account) {
            return new Promise((_, reject) => {
                reject(`ERROR: No account found to get portfolio, tried: ${account}`);
            });
        }
        const path = `portafolios/${account}`;
        return api.get(path, { data: null });
    },
    getSimlutedPortfolio() {
        const path = `portafolios`;
        return api.get(path, { data: null });
    },
    deleteItem({ idPortfolio }) {
        const path = `portafolios/${idPortfolio}`;
        return api.delete(path, { data: null });
    },
    addSimulatedPortfolio(payload) {
        const path = `portafolios`;
        return api.post(path, payload);
    },
    editSimulatedPortfolio(payload) {
        const path = `portafolios`;
        return api.put(path, payload);
    },
};
