import {
    helpers,
    required as vRequired,
    requiredIf as vRequiredIf,
    minLength as vMinLength,
    maxLength as vMaxLength,
} from '@vuelidate/validators';

import { removeFormat } from '@/directives/currencyFormat';
import { isValidPrice } from '@/utils/isValidPrice';

import { MAX_CUOTA_PERCENT } from '@/consts';

import { i18n } from '@/i18n';

const { t } = i18n.global;

// required with custom message

export const required = helpers.withMessage(t('rules.required'), vRequired);
export const requiredIf = (condition) =>
    helpers.withMessage(t('rules.requiredIf'), vRequiredIf(condition));

// Number related

export const overZero = (val, msg = t('transactions.balanceIsZero')) => {
    return helpers.withParams(
        { val },
        helpers.withMessage(msg, () => val > 0)
    );
};

export const maxValue = (max, msg = t('transactions.moreThanBalance')) => {
    return helpers.withParams(
        { max },
        helpers.withMessage(msg, (val) => {
            return max >= removeFormat(val);
        })
    );
};
export const moreThanPercent = (
    max,
    msg = t('transactions.moreThanBalance')
) => {
    return helpers.withParams(
        { max },
        helpers.withMessage(msg, (val) => {
            const percent = (max * MAX_CUOTA_PERCENT) / 100;
            if (max === val) {
                return true;
            }
            return percent >= val;
        })
    );
};

export const multipleOfValue = (orderValue) => {
    return helpers.withParams(
        { orderValue },
        helpers.withMessage(
            () => isValidPrice(orderValue),
            () => !isValidPrice(orderValue) 
        )
    );
};

// VERY SPECIFIC FOR A CERTAIN FORMAT, DO NOT REUSE
export const maxPercentValue = (max, msg = t('rules.moreThanPercentage')) => {
    return helpers.withParams(
        { max },
        helpers.withMessage(msg, (val) => {
            if (!val) {
                return true;
            }
            return max >= parseFloat(val);
        })
    );
};

export const minValue = (min, msg = t('transactions.lessThanMin')) => {
    return helpers.withParams(
        { min },
        helpers.withMessage(msg, (val) => removeFormat(val) >= min)
    );
};

export const nonZero = (msg = t('transactions.lessThanMin')) => {
    return helpers.withMessage(msg, (val) => removeFormat(val) !== 0);
};

export const sameValue = (value, msg = t('transactions.notEqual')) => {
    return helpers.withParams(
        { value },
        helpers.withMessage(msg, (val) => val === value)
    );
};

export const valueIn = (arr, msg = t('transactions.unavailableTerm')) => {
    return helpers.withParams(
        { arr },
        helpers.withMessage(msg, (val) => arr.includes(val))
    );
};

// String related

export const minLength = (length) =>
    helpers.withMessage(t('rules.minCharacters', length), vMinLength(length));

export const maxLength = (length) =>
    helpers.withMessage(t('rules.maxCharacters', length), vMaxLength(length));

export const hasLower = (msg = t('rules.lowercase')) => {
    const re = /[a-z]/;
    return helpers.withMessage(msg, (val) => re.test(val));
};

export const hasUpper = (msg = t('rules.uppercase')) => {
    const re = /[A-Z]/;
    return helpers.withMessage(msg, (val) => re.test(val));
};

export const hasNumber = (msg = t('rules.number')) => {
    const re = /\d/;
    return helpers.withMessage(msg, (val) => re.test(val));
};

export const hasSymbol = (msg = t('rules.symbol')) => {
    const re = /[@#$%:;()+_=.,\-^&*]/;
    return helpers.withMessage(msg, (val) => re.test(val));
};

export const isEqual = (text, msg = t('rules.coincidence')) => {
    return helpers.withParams(
        { text },
        helpers.withMessage(msg, (val) => val === text)
    );
};
export const itMustToBeDifferent = (
    text,
    msg = t('rules.itMustToBeDifferent')
) => {
    return helpers.withParams(
        { text },
        helpers.withMessage(msg, (val) => val !== text)
    );
};

export const isRUT = (msg = t('rules.rut')) => {
    return helpers.withMessage(msg, (val) => {
        // rut is no required per se
        if (!val) {
            return true;
        }
        let tmp, dv, number;
        if (val.includes('-')) {
            tmp = val.replace(/\./g, '').split('-');
            dv = tmp[1];
            number = tmp[0];
        } else {
            // Just in case we have a clean numeric RUT. IE: 123123123 instead of 12312312-3
            dv = String(val).substring(String(val).length - 1);
            number = String(val).slice(0, -1);
        }

        let M = 0;
        let S = 1;
        let T = number;
        for (; T; T = Math.floor(T / 10)) {
            S = (S + (T % 10) * (9 - (M++ % 6))) % 11;
        }
        return (S ? S - 1 : 'k') == dv;
    });
};

export const isMail = (msg = t('rules.mail')) => {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // NOSONAR
    return helpers.withMessage(
        msg,
        (val) => re.test(String(val).toLowerCase()) || !val
    );
};

export const isPhone = (msg = t('rules.phone')) => {
    return helpers.withMessage(
        msg,
        (value) => /\d/g.test(value) && value.length === 9
    );
};
