import { useIntercom } from '@homebaseai/vue3-intercom';
import { useUserStore } from '@/_store/user.store';
import { useAuthStore } from '@/_store/auth.store';
import { RISK, EVENTS } from '@/consts';
import { watch, onMounted, onUnmounted, provide } from 'vue';
import { useRootEvents } from './useRootEvents';
import { useAccountsStore } from '@/_store/accounts.store.js';
import { storeToRefs } from 'pinia';

export const useIntercomChat = () => {
    const userStore = useUserStore();
    const authStore = useAuthStore();
    const intercom = useIntercom();
    const { addListener, removeListeners } = useRootEvents();
    const accountStore = useAccountsStore();
    const { hasEjecutivoDigital } = storeToRefs(accountStore);

    provide('intercom', intercom);

    // display it
    const popIntercom = () => intercom.show();

    const shutdown = () => {
        if (!window.Intercom) return;
        intercom.shutdown();
    };

    const initIntercom = () => {
        const config = {
            app_id: import.meta.env.VITE_APP_INTERCOM_APPID,
            hide_default_launcher: true,
        };
        if (window.Intercom) {
            window.Intercom('boot', config);
        } else {
            intercom.boot(config);
        }
    };
    const updateIntercom = () => {
        if (!window.Intercom) {
            return;
        }
        if (!userStore.user.email) {
            return;
        }

        const email = (() => {
            if (!userStore.user.email) {
                return '';
            }
            const _e = userStore.user.email?.split('\n');
            return _e[0];
        })();

        const config = {
            name: `${userStore.user.name} ${userStore.user.lastName}`,
            email: email,
            external_id: userStore.user.rut,
            obolEnabled: authStore.obolEnabled,
            isbtg: userStore.user.btg,
            digital: hasEjecutivoDigital.value,
            obolcode: userStore.user.onboardingCode,
            riskProfile: userStore.user.extraData?.perfilInversion
                ? RISK[userStore.user.extraData?.perfilInversion].value
                : null,
            naturalPerson: userStore.user.extraData?.tipoPersona === '1',
            user_hash: userStore.user.intercompHash,
        };

        if (userStore.user.extraData?.idBp) {
            config.user_id = userStore.user.extraData?.idBp;
        }

        const utm = JSON.parse(localStorage.getItem('UTM'));
        if (utm !== null) {
            config.utm_source = utm.utm_source;
            config.utm_medium = utm.utm_medium;
            config.utm_campaign = utm.utm_campaign;
            localStorage.removeItem('UTM');
        }

        intercom.update(config);
    };

    onMounted(() => {
        // listerner added to open intercom
        addListener(EVENTS.INTERCOM_OPEN, () => {
            intercom.show();
        });
        addListener(EVENTS.INTERCOM_OPEN_SPACE, (event) => {
            intercom.$intercom('showSpace', event.space)();
        });
    });

    onUnmounted(() => {
        removeListeners();
    });

    watch(
        () => authStore.isLoggedIn,
        (value) => {
            if (!value) {
                shutdown();
                initIntercom();
            }
        }
    );

    watch(
        // Here we listen all values we want to send to intercom
        [
            () => userStore.user,
            () => accountStore.currentAccount,
            () => accountStore.hasEjecutivoDigital,
        ],
        () => {
            shutdown();
            initIntercom();
            updateIntercom();
        },
        { immediate: true, deep: true }
    );

    return {
        popIntercom,
        shutdown,
        initIntercom,
        updateIntercom,
    };
};
