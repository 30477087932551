<template>

    <template v-if="step === 'init' && component">
        <component
            :is="component"
            v-if="hasInstrumentBalance"
            :inverse="inverse"
            v-bind="{
                item,
                balance,
                availableAmount,
                availableQuantity,
                operation,
                currency,
                term,
            }"
            @cancel="emit('cancel')"
        />
        <component :is="noBalanceComponent" v-else />
    </template>
    <ProfileConfirm
        v-else-if="step === 'risk'"
        :inverse="inverse"
        @cancel="onCancel"
    />
    <!-- This step is only for DAP -->
    <SimulationResult v-else-if="step === 'simulation'" :inverse="inverse" />
    <component
        :is="confirmComponent"
        v-else-if="step === 'confirm'"
        :inverse="inverse"
        :back="!blockedBackBtns.includes('confirm')"
        v-bind="{
            item,
            account: account.idCuentaGrupo,
            balance,
        }"
    />
    <component
        :is="completedComponent"
        v-else-if="step === 'completed'"
        :inverse="inverse"
        v-bind="{ item }"
    />
    <component
        :is="closedComponent"
        v-else-if="step === 'closed'"
        :inverse="inverse"
        v-bind="{ item, operation }"
    />
    <OperationError v-else-if="step === 'error'" v-bind="{ item }" />
</template>

<script setup>
import { useOrderStore } from '@/_store/order.store.js';
import { storeToRefs } from 'pinia';
import { useRootEvents } from '@/composables/useRootEvents';
import { EVENTS, OPERATION } from '@/consts';

import ConfirmOperation from '@/components/transactional-funds/Shared/ConfirmOperation.vue';
import ProfileConfirm from '@/components/transactional-funds/Shared/ProfileConfirm.vue';
import OperationCompleted from '@/components/transactional-funds/Shared/OperationCompleted.vue';
import MarketClosed from '@/components/transactional-funds/Shared/MarketClosed.vue';
import OperationError from '@/components/transactional-funds/Shared/OperationError.vue';
import SimulationResult from '@/components/transactional-funds/DAP/SimulationResult.vue';
import NoBalance from '@/components/transactional-funds/Shared/NoBalance.vue';

import { computed, inject, watch } from 'vue';

const orderStore = useOrderStore();
const { step, stepIndex } = storeToRefs(orderStore);

const emit = defineEmits(['cancel']);

import { useAccountsStore } from '@/_store/accounts.store.js';
const accountsStore = useAccountsStore();
const { accounts, currentAccount } = storeToRefs(accountsStore);

const account = computed(() => {
    return currentAccount.value || accounts.value[0];
});

const onCancel = () => {
    step.value = 0;
    emit('cancel');
};

const { addListener } = useRootEvents();
addListener(EVENTS.TRANSACCIONAL_OPEN, () => {
    stepIndex.value = 0;
});


const props = defineProps({
    item: {
        type: Object,
        default: () => {},
    },
    balance: {
        type: Number,
        default: 0,
    },
    availableAmount: {
        type: Number,
        default: 0,
    },
    availableQuantity: {
        type: Number,
        default: 0,
    },
    operation: {
        type: Number,
        default: OPERATION.BUY,
    },
    component: {
        type: Object,
        required: true,
    },
    confirmComponent: {
        type: Object,
        default: () => ConfirmOperation,
    },
    completedComponent: {
        type: Object,
        default: () => OperationCompleted,
    },
    noBalanceComponent: {
        type: Object,
        default: () => NoBalance,
    },
    closedComponent: {
        type: Object,
        default: () => MarketClosed,
    },
    loading: {
        type: Boolean,
        default: false,
    },
    hasInstrumentBalance: {
        type: Boolean,
        default: true,
    },
    inverse: {
        type: Boolean,
        default: false,
    },
    term: {
        type: Number,
        default: 7,
    },
    currency: {
        type: String,
        default: 'CLP',
    },
    blockedBackBtns: {
        type: Array,
        default: () => [],
    },
    intercom: {
        type: Object,
        default: () => ({}),
    }
});

const intercomWrapper = inject('intercom');
watch(() => step.value, (newStep) => {
    if (newStep === 'completed' && Object.keys(props.intercom) != 0) {
        intercomWrapper.trackEvent('invierte_autoinvest', props.intercom)();
    }
}, { immediate: true });
</script>
