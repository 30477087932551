import api from './api.js';
import { encodeFormData } from '@/utils/text';

export default {
    login(params) {
        const path = 'user/login';
        // const headers = {
        //   "Content-Type": "application/x-www-form-urlencoded",
        //   Accept: "application/json"
        // };
        const form = encodeFormData(params);
        return api.post(path, form);
    },
    loginToken({ time, user, token }) {
        const path = 'user/loginToken';
        return api.post(path, { time, user, token });
    },
    loginAgent(data) {
        const form = encodeFormData({ data });
        return api.post('user/login/external', form);
    },
    logout() {
        //   Auth required - passed on headers
        const path = 'user/logout';
        return api.post(path, {});
    },
    renew() {
        const path = 'user/renew';
        return api.get(path, { data: null });
    },
    forgot({ rut }) {
        const path = `user/${rut}/forgot`;
        return api.post(path, { rut });
    },
    generatePass({ rut, code, seconds = 1 }) {
        const path = `user/${rut}/forgot/generatePass`;
        // service needs params as payload and in the url for it to work...
        return api.put(path, { code, seconds }, { params: { code, seconds } });
    },
    changePass({ oldPassword, newPassword }) {
        const path = `user/password`;
        return api.put(path, null, { params: { oldPassword, newPassword } });
    },
    register({
        active = true,
        email,
        rut,
        phone,
        password,
        friendCode = '',
        captcha,
        hashAgencia = '',
    }) {
        const path = `user`;
        return api.post(path, {
            active,
            email,
            rut,
            phone,
            password,
            friendCode,
            captcha,
            hashAgencia,
        });
    },
};
