import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { useContractsStore } from './contracts.store';

import { useUserStore } from './user.store';
import executiveService from '@/_services/executive.service';
import investmentsService from '@/_services/investments.service';
import { errorHandler } from '@/_services/errorHandler';
import { ACCOUNT_TYPE } from '@/consts';
/**
 * Important: We are using 'Setup Store' definition.
 * @see https://pinia.vuejs.org/core-concepts/#setup-stores
 *
 * ref()s become state properties
 * computed()s become getters
 * function()s become actions
 *
 */
export const useAccountsStore = defineStore(
    'accounts',
    () => {
        const userStore = useUserStore();

        const selected = ref(null);
        const accounts = ref([]);
        const accountsBank = ref([]);
        const selectedBank = ref(null);
        const isAccountBank = ref(false);
        const executive = ref({});

        const loading = computed(() => {
            return userStore.loading;
        });

        const currentAccount = computed(() => {
            if (loading.value === true) {
                console.log('Accounts: Loading');
                return {};
            }

            if (accounts.value.length === 0) {
                console.log('Accounts: No accounts');
                return {};
            }

            // Selected account will be the first one by default
            if (!selected.value) {
                return accounts.value[0];
            }

            return accounts.value.find(
                (i) => i.idCuentaGrupo === selected.value
            );
        });

        const currentAccountBank = computed(() => {
            if (loading.value === true) {
                return {};
            }
            if (accountsBank.value.length === 0) {
                return {};
            }

            // Selected account will be the first one by default
            if (!selectedBank.value) {
                return accountsBank.value[0];
            }

            return accountsBank.value.find(
                (i) => i.idCuentaGrupo === selectedBank.value
            );
        });

        const hasEjecutivoDigital = computed(() => {
            if (!selected.value) {
                return false;
            }
            if (isAccountBank.value) {
                return false;
            }
            return currentAccount.value.rutCanal === '656437';
        });

        const getAccounts = () => {
            /**
             * Accounts are coming from extra data from User store.
             */

            return new Promise((resolve) => {
                accounts.value = userStore.user.extraData?.cuentas ?? [];

                accountsBank.value = accounts.value.filter((item) => {
                    return item.tipoCuenta === ACCOUNT_TYPE.BANK;
                });

                accounts.value = accounts.value.filter((item) => {
                    return item.tipoCuenta !== ACCOUNT_TYPE.BANK;
                });

                resolve();
            });
        };

        const getAccountsBank = async () => {
            if (accountsBank.value.length === 0) {
                return {};
            }
            isAccountBank.value = true;
            if (!selectedBank.value) {
                selectedBank.value = accountsBank.value[0].idCuentaGrupo;
            }

            return accountsBank.value.find(
                (i) => i.idCuentaGrupo === selectedBank.value
            );
        };

        const loadingExecutive = ref(false);
        const getExecutive = async () => {
            if (!selected.value) {
                return;
            }
            loadingExecutive.value = true;
            try {
                await executiveService
                    .get({ account: selected.value })
                    .then((res) => (executive.value = res.data));
            } catch (error) {
                console.log(console.error());
            } finally {
                loadingExecutive.value = false;
            }
        };

        const accountsC4Only = computed(() => {
            return accounts.value.filter(
                (i) => i.tipoCuenta === ACCOUNT_TYPE.INVESTMENT
            );
        });

        const contractsStore = useContractsStore();

        // This function attempts to select a default account from an accounts list.
        const selectDefaultAccount = async (options = {}) => {
            // Default options
            const defaultOptions = {
                selectFirstC4Account: false, // You can define other default options here
            };

            // Merge default options with the passed options
            const mergedOptions = { ...defaultOptions, ...options };

            // If I have no account I'm not doing anything
            if (accounts.value.length === 0) {
                console.log('No accounts to select');
                return;
            }

            // Check a c4 if there are any accounts in the list.
            if (
                mergedOptions.selectFirstC4Account &&
                accountsC4Only.value.length > 0
            ) {
                // Set the selected account to be the first account in the list.
                selected.value = accountsC4Only.value[0].idCuentaGrupo;
            } else {
                // Set the selected account to be the last account in the list.
                selected.value =
                    accounts.value[accounts.value.length - 1].idCuentaGrupo;
            }

            // Use the executive service to fetch data related to the selected account.
            executiveService.get({ account: selected.value }).then(
                (res) =>
                    // After receiving the response, set the executive value to be the fetched data.
                    (executive.value = res.data)
            );

            if (userStore.user.btg) await contractsStore.getPendingContracts();
        };

        const selectAccount = async (id) => {
            selected.value = id;
            const res = await executiveService.get({ account: id });
            executive.value = res.data;

            if (userStore.user.btg) await contractsStore.getPendingContracts();

            return id; // Return id for testing purposes. Can be removed.
        };

        const selectAccountBank = async (id) => {
            selectedBank.value = id;
            return id; // Return id for testing purposes. Can be removed.
        };

        const naturalAccounts = computed(() => {
            return accounts.value; // TODO: separar entre persona natural y jurídica
        });

        const legalAccounts = computed(() => {
            return []; // TODO: separar entre persona natural y jurídica
        });

        const bankAccounts = computed(() => {
            return accountsBank.value;
        });

        const $reset = () => {
            selected.value = null;
            accounts.value = [];
            selectedBank.value = null;
            accountsBank.value = [];
            isAccountBank.value = false;
        };

        const hasAccounts = computed(() => accounts.value.length > 0);

        const transferAccount = ref(null);
        const loadingTransferAccount = ref(false);
        const getTransferAccount = async () => {
            try {
                loadingTransferAccount.value = true;
                const res = await investmentsService.getTransferAccount();
                transferAccount.value = res.data;

                loadingTransferAccount.value = false;
                return res;
            } catch (error) {
                errorHandler(error);
            }
        };
        const setTransferAccount = async (id) => {
            try {
                await investmentsService.updateTransferAccount(id);
                await getTransferAccount();
            } catch (error) {
                errorHandler(error);
            }
        };

        const setDefaultAccount = () => {
            selected.value = accounts.value[0].idCuentaGrupo;
            selectedBank.value = null;
            isAccountBank.value = false;
        };

        return {
            loading,
            selected,
            accounts,
            currentAccount,
            accountsC4Only,
            executive,
            loadingExecutive,
            getExecutive,
            getAccounts,
            selectAccount,
            selectDefaultAccount,
            naturalAccounts,
            hasEjecutivoDigital,
            legalAccounts,
            $reset,
            hasAccounts,
            transferAccount,
            loadingTransferAccount,
            getTransferAccount,
            setTransferAccount,
            accountsBank,
            getAccountsBank,
            currentAccountBank,
            isAccountBank,
            bankAccounts,
            setDefaultAccount,
            selectedBank,
            selectAccountBank,
        };
    },
    {
        persist: {
            storage: localStorage,
            paths: ['accounts', 'selected', 'accountsBank', 'selectedBank'],
        },
    }
);
