export default {
    date: {
        today: 'Hoy',
        tomorrow: 'Mañana',
        medium: 'DD [de] MMMM',
        long: 'DD/MM/YYYY [a las] HH:mm [hrs.]',
        payload: 'YYYY-MM-DD',
        default: 'DD/MM/YYYY',
        now: 'Ahora',
        previous: 'Antes',
        historical: 'Históricas',
    },
    consts: {
        risk: {
            uc: {
                label: 'Ultra Conservador',
                meaning:
                    'Esto significa que buscas mantener tu capital a toda costa. Prefieres no arriesgar mucho en tus inversiones y optas por tener ganancias pequeñas, pero con la seguridad de preservar tu patrimonio.',
            },
            c: {
                label: 'Conservador',
                desc: 'Valoras la seguridad y evitas el riesgo. Prefieres ganancias bajas, pero que tu inversión esté segura.',
                meaning:
                    'Esto significa que priorizas mantener tu capital. Puedes tolerar algunos riesgos, aunque mínimos, con tal de obtener una rentabilidad baja pero relativamente estable.',
            },
            b: {
                label: 'Moderado',
                desc: 'Eres cauteloso en tus decisiones, pero estás dispuesto a asumir algo riesgo para aumentar tus ganancias.',
                meaning:
                    'Esto significa que tratas de balancear el obtener ganancias con preservar tu capital. Al invertir buscas tomar algunos riesgos, aunque controlados, con tal de obtener una rentabilidad razonable.',
            },
            a: {
                label: 'Agresivo',
                nicename: 'Arriesgado',
                desc: 'Buscas mejores rendimientos, por lo que estás dispuesto a asumir un riesgo medio/alto.',
                meaning:
                    'Esto significa que buscas activamente obtener ganancias usando tu capital. Arriesgas en tus inversiones y soportas períodos de volatilidad con tal de obtener altas rentabilidades.',
            },
            ua: {
                label: 'Ultra Agresivo',
                meaning:
                    'Esto significa que buscas activamente obtener ganancias usando tu capital. Arriesgas en tus inversiones y soportas períodos de volatilidad con tal de obtener altas rentabilidades.',
            },
        },
        typesData: {
            measure: 'cuota',
            measures: 'cuotas',
            sell: 'Vender',
            buy: 'Comprar',
            retrieve: 'Rescatar',
            contribute: 'Aportar',
            withdrawal: 'Retirar',
            pay: 'Abonar',
            operations: {
                sell: 'Venta',
                buy: 'Compra',
                retrieve: 'Rescate',
                contribute: 'Aporte',
                withdrawal: 'Retiro',
                pay: 'Abono',
            },
            accion: {
                name: 'Acciones',
                nameType: 'Acción',
                measure: 'acción | acción | acciones',
                measures: 'acciones',
                variacion: '1 día',
                title: 'Compra acciones de {nemo} - BTG Pactual',
                description: `Invierte en {nemo} con análisis, información y gráficos en tiempo real con BTG Pactual, el mayor banco de inversión de Latinoamérica`,
            },
            fondoMutuo: {
                name: 'Fondos mutuos locales',
                nameType: 'Fondo Mutuo',
                measure: 'cuotas | cuota | cuotas',
                variacion: '30 días',
                title: 'Invierte en {name} - BTG Pactual',
                description: `Encuentra fondos de {manager} y de las principales administradoras locales e internacionales. Accede a la plataforma de fondos más completa del mercado`,
            },
            fondoMultitype: {
                name: 'Fondos de inversión',
                nameType: 'Fondo de Inversión',
                measure: 'cuotas | cuota | cuotas',
                variacion: '30 días',
                title: 'Invierte en {name} - BTG Pactual',
                description: `Encuentra fondos de {manager} y de las principales administradoras locales e internacionales. Accede a la plataforma de fondos más completa del mercado`,
            },
            fondoInversion: {
                name: 'Fondos de inversión',
                nameType: 'Fondo de Inversión',
                measure: 'cuotas | cuota | cuotas',
                variacion: '30 días',
                title: 'Invierte en {nemo} - {name}',
                description: `Encuentra fondos de {manager} y de las principales administradoras locales e internacionales. Accede a la plataforma de fondos más completa del mercado`,
            },
            notas: {
                name: 'Notas Estructuradas',
                nameType: 'Notas Estructuradas',
                measure: 'nominal',
                measures: 'nominales',
                variacion: '30 días',
                title: 'Invierte en {name} - BTG Pactual',
                description: `Invierte online en notas estructuradas y muchos más en Mercados en Línea de BTG Pactual, el mayor banco de inversiones de Latam`,
            },
            fondoInternacional: {
                name: 'Fondos mutuos internacionales',
                nameType: 'Fondo Mutuo Internacional',
                measure: 'cuotas | cuota | cuotas',
                variacion: '30 días',
                title: 'Invierte en {name} - BTG Pactual',
                description: `Encuentra fondos de {manager} y de las principales administradoras locales e internacionales. Accede a la red de fondos extranjeros más grande del mundo`,
            },
            moneda: {
                name: 'Dólares',
                nameType: 'Dólar',
                measure: 'dólar',
                measures: 'dólares',
                variacion: '1 día',
                title: 'Compra y vende Dólares al mejor precio - BTG Pactual',
                description: `Compra, vende e invierte en Dólares al mejor precio en Mercados en Línea de BTG Pactual, el mayor banco de inversiones de Latam`,
            },
            monedaInversion: {
                name: 'Dólares',
                nameType: 'Dólar',
                variacion: '1 día',
            },
            tasa: {
                name: 'Tasa',
                nameType: 'Tasa de Interés',
                variacion: '30 días',
            },
            indice: {
                name: 'Índice',
                nameType: 'Índice',
                title: `Precios de todos los mercados - BTG Pactual`,
                description: `Precios en tiempo real de acciones, UF, dólar y más. Revisa información histórica, crea alertas de precio y simula inversiones el mejor monitor financiero de Chile`,
                variacion: '1 día',
            },
            otro: {
                name: 'Otros',
                nameType: 'Otro',
                title: `-`,
                description: `-`,
                variacion: '1 día',
            },
            hedgeFunds: {
                name: 'Hedge Funds',
                nameType: 'Hedge Fund',
                title: `-`,
                description: `-`,
                variacion: '1 día',
            },
            certificado: {
                name: 'Certificados',
                nameType: 'Certificado',
                title: `-`,
                description: `-`,
                variacion: '1 día',
            },
            cajaContado: {
                name: 'Caja Contado',
            },
            cajaCompromisos: {
                name: 'Caja Compromisos Futuros',
                nameType: 'Futuros',
            },
            fondosTransito: {
                name: 'Fondos en tránsito',
                nameType: 'En transito',
            },
            depositoPlazo: {
                name: 'Depósitos a plazo',
                nameType: 'Depósito a Plazo',
                measure: 'depósito',
                measures: 'depósitos',
                title: 'Invierte en Depósito a Plazo en Pesos - BTG Pactual',
                description: `Las mejores tasas para invertir online en Depósitos a Plazo en Pesos están en Mercados en Línea de BTG Pactual, el mayor banco de inversiones de Latam`,
            },
            rentaFija: {
                name: 'Renta fija',
                nameType: 'Renta Fija',
            },
            opciones: {
                name: 'Opciones',
                nameType: 'Opciones',
            },
            billetera: {
                name: 'Saldo en cuenta',
                nameType: 'Billetera',
            },
            commodity: {
                name: 'Commodities',
                nameType: 'Commodity',
                variacion: '30 días',
            },
            fondoInmobiliario: {
                name: 'Fondos Inmobiliarios',
                nameType: 'Fondo Inmobiliario',
                measure: 'cuotas | cuota | cuotas',
            },
            forward: {
                name: 'Forward',
                nameType: 'Forward',
                measure: 'cuotas | cuota | cuotas',
            },
            giro: {
                name: 'Giro',
                nameType: 'Giro',
            },
            abono: {
                name: 'Abono',
                nameType: 'Abono',
            },
            default: {
                name: 'Desconocido',
                nameType: 'Desconocido',
            },
        },
        states: {
            ingresado: 'En proceso',
            noIngresado: 'No ejecutado',
            ejecutado: 'Ejecutado',
            cancelado: 'Cancelado',
            vencido: 'Vencido',
        },
        dap: {
            clpSevenDays: 'DAP BTG CLP - 7 DÍAS',
            clpOneMonth: 'DAP BTG CLP - 1 MES',
            ufOneYear: 'DAP BTG UF - 1 AÑO',
        },
    },
    normalize: {
        activity: {
            transfer: 'Transferencia electrónica',
            paymentButton: 'Botón de pago',
        },
    },
    header: {
        profile: 'Perfil de inversionista',
        actions: {
            profile: 'Mi perfil',
            account: 'Cambiar cuenta',
            logoff: 'Cerrar sesión',
        },
    },
    portafolio: {
        title: 'Portafolio',
        content: {
            title: 'Contenido destacado',
        },
        welcome: {
            title: `¡Hola, {name}!`,
            tagline: `Lleva un registro de tus activos, el rendimiento de tu cartera y las transacciones de tu cuenta.`,
        },
        distribution: {
            title: `Distribución`,
            legend: {
                title: `Tu patrimonio está distribuido en {quantity} categoría{plural}`,
            },
            dropdown: {
                in: 'En',
                product: 'producto{plural}',
                timeDepositProduct: 'Producto',
                expiration: 'Vencimiento',
                monthlyRate: 'Tasa mensual',
                marketRate: 'Tasa mercado',
                buyRate: 'Tasa compra',
                annualRate: 'Tasa anual',
                initialAmount: 'Monto inicial',
                profitability: 'Rentabilidad',
                profitab: 'Rentab.',
                profitTooltip:
                    'Rentabilidad no incluye dividendos u otras distribuciones',
                valuation: 'Valorización',
                custodian: 'Custodio',
                purchaseDate: 'Último movimiento',
                amountAtExpiration: 'Monto al vencimiento',
                portfolioPercent: 'Porcentaje del portafolio',
                quantity: 'Cantidad',
                series: 'Serie',
                purchasePrice: 'P. Compra',
                purchasePriceLong: 'Precio compra',
                currentPrice: 'P. Actual',
                currentPriceLong: 'Precio actual',
                purchasePriceAlt: 'Monto compra',
                sellPriceAlt: 'Monto venta',
                contribute: 'Aportar',
                retrieve: 'Rescatar',
                buy: 'Comprar',
                sell: 'Vender',
                fundDetails: 'Detalles del fondo',
                shareDetails: 'Detalles de la acción',
                buyDate: 'Último movimiento',
                initDate: 'Fecha de inicio',
                rating: 'Rating',
                currency: 'Moneda',
                nominales: 'Nominales',
                operation: 'Operación',
            },
            evolution: {
                title: 'Evolución',
                oneWeek: '1 semana',
                oneMonth: '1 mes',
                oneYear: '1 año',
                all: 'Todo',
                profitContext: 'Tu portafolio ha rentado',
                profitPeriod: 'en los últimos {period}',
                profitAll: 'desde el inicio',
                sevenDays: '7 días',
                thirtyDays: '30 días',
                twelveMonths: '12 meses',
                profitTooltip:
                    'Calculamos tu rentabilidad usando la Tasa Interna de Retorno (TIR), que incluye los abonos y retiros del periodo seleccionado. Los cálculos se basan en el patrimonio al finalizar el último día hábil. Encuentra <a href="https://ayuda.btgpactual.cl/es/articles/6766346-como-calculan-la-rentabilidad-de-mi-patrimonio" target="_blank"> más detalles aquí.</a>',
                amountAt: 'Patrimonio al {date}',
                amount: 'Patrimonio',
                contributions: 'Abonos',
                withdrawals: 'Retiros',
                profitability: 'Rentabilidad',
                lastUpdate: 'Última actualización: {date}',
            },
            chartArea: {
                profitContext: 'Este fondo ha rentado',
                profitContextShare: 'Variación de precio en el período:',
                profitPeriod: 'en los últimos {period}',
                profitCustom: 'en el {period}',
                oneDay: 'Día hábil',
                oneWeek: '1 semana',
                oneMonth: '1 mes',
                thirtyDays: '30 días',
                threeMonths: '3 meses',
                sixMonths: '6 meses',
                twelveMonths: '12 meses',
                twoYears: '24 meses',
                oneYear: '1 año',
                currentYear: 'Año actual',
                updated: 'Datos actualizados al: {date}',
                variation: 'variación en el período',
            },
        },
        resume: {
            title: 'Patrimonio',
            updated: 'Última actualización',
            period: 'en los últimos 12 meses',
            periodTooltip:
                'Calculamos tu rentabilidad usando la Tasa Interna de Retorno (TIR), que incluye los abonos y retiros del periodo seleccionado. Los cálculos se basan en el patrimonio al finalizar el último día hábil. Encuentra <a href="{link}" target="_blank">más detalles aquí.</a>',
            netPayments: 'Abonos netos',
            netPaymentsTooltip:
                'Es la suma de todos los abonos a tu cuenta en BTG Pactual, menos todos los giros hacia tu cuenta bancaria. Considera sólo los movimientos desde el 1 de enero de 2022.',
            clpBalance: 'Saldo disponible en CLP',
            clpBalanceTooltip: '[Tooltip pendiente]',
            usdBalance: 'Saldo disponible en USD',
            usdBalanceTooltip: '[Tooltip pendiente]',
            paymentsTooltip:
                'Es la suma de todos los abonos a tu cuenta en BTG Pactual, según filtro o en su defecto, el mes en curso.',
            retirementTooltip:
                'Es la suma de todos los cargos a tu cuenta en BTG Pactual, según filtro o en su defecto, el mes en curso.',
            balance: 'Saldo disponible',
            balanceMonth: 'Saldo al cierre',
            payments: 'Total Abonos',
            retirement: 'Total Cargos',
        },
        latest: {
            title: 'Últimas actividades',
            link: 'Ver detalle',
            dateDetail: 'Movimientos al último día hábil',
            types: {
                share: {
                    buy: 'Compra de acciones',
                    sell: 'Venta de acciones',
                },
                fund: {
                    buy: 'Aporte',
                    Sell: 'Rescate',
                },
                bdp: {
                    buy: 'Saldo para invertir',
                },
            },
        },
        asesorias: {
            title: 'Asesoría de inversiones',
            link: 'Ver asesoría',
        },
        footer: {
            title: 'Lorem, ipsum',
            body: 'Sem taxa de abertura ou de manutenção, a conta corrente BTG permite que você faça transferências, pagamentos, agendamentos e muito mais.',
        },
        limited: {
            hero: {
                incomplete: {
                    title: 'Ya casi estás',
                    subtitle:
                        'Finaliza tu registro y comienza a invertir 100% digital.',
                    prompt: 'Finalizar registro',
                },
                oldMEL: {
                    title: 'Ya casi estás',
                    subtitle:
                        'Para invertir con BTG Pactual actualiza tu cuenta',
                    prompt: 'Actualizar cuenta',
                },
                processing: {
                    title: 'Ya casi estás',
                    subtitle:
                        'Estamos finalizando la creación de tu cuenta. En poco tiempo podrás invertir con BTG Pactual.',
                },
                waiting: {
                    title: 'Ya casi estás',
                    subtitle:
                        'Estamos procesando la creación de tu cuenta. En poco tiempo podrás invertir con BTG Pactual.',
                },
                semiPringado: {
                    title: 'Contáctanos',
                    subtitle:
                        'Hubo un problema en la creación de tu cuenta. Escríbenos por el chat.',
                    prompt: 'Ir al chat',
                },
                minor: {
                    title: 'Todavía no',
                    subtitle:
                        'Podrás invertir al cumplir 18 años. Por hoy, explora precios, configura alertas personalizadas y crea tu propia lista de favoritos con Mercados en Línea de BTG Pactual.',
                    prompt: 'Ir a Mercados en Línea',
                },
                pringado: {
                    title: 'Lo sentimos',
                    subtitle:
                        'Por ahora, no puedes invertir con BTG Pactual. Explora precios, configura alertas personalizadas y crea tu propia lista de favoritos con Mercados en Línea de BTG Pactual.',
                    prompt: 'Ir a Mercados en Línea',
                },
                legalPerson: {
                    title: 'Contáctanos',
                    subtitle:
                        'Para abrir una cuenta de persona jurídica, escríbenos por el chat.',
                    prompt: 'Ir al chat',
                },
            },
            shortcuts: {
                title: 'Como cliente, tendrás acceso completo a nuestra plataforma y todo el respaldo de nuestros expertos',
                subtitle: 'Explora los productos que BTG Pactual tiene para ti',
            },
        },
    },
    productos: {
        home: 'Inicio',
        title: 'Productos de inversión',
        catalog: {
            title: 'Productos de inversión',
            subtitle:
                'Conoce todos los productos que BTG Pactual tiene para ofrecerte de manera 100% digital.',
        },
        goals: {
            title: 'Alcanza tus objetivos',
        },
        card: {
            button: 'Invertir',
            details: 'Ver detalle',
        },
        descubre: {
            title: 'Descubre en BTG',
        },
        autoinvest: {
            title: 'AutoInvest',
            content:
                'Tus inversiones en piloto automático. Deja que nuestro equipo experto BTG Pactual se encargue de gestionar tus ahorros según tu perfil de riesgo. Perfecto para invertir sin preocuparse de nada.',
            linkText: 'Invierte con AutoInvest',
        },
        asesoria: {
            title: 'Asesoría',
            tip: 'Ten asesoría personalizada para conocer las mejores inversiones según su perfil',
            button: 'Ver asesoría',
        },
        fondos: {
            title: 'Fondos locales',
            subtitle:
                'Explora nuestra selección de fondos mutuos, de inversión y ETFs de las mejores administradoras del país',
            fundsOperations: 'Operaciones de fondos locales',
            fundsLearning: 'Aprende sobre fondos locales',
            help: 'Ayuda',
            searchBy: 'Busca por nombre o nemotécnico',
            all: 'Todos',
            topFunds: 'Top Funds',
            fixedIncome: 'Renta fija',
            variableIncome: 'Renta variable',
            balanced: 'Balanceados',
            alternatives: 'Alternativos',
            filters: 'Filtros',
            topFundsDesc:
                'La selección de los mejores fondos según BTG Pactual.',
            moreInfo: 'Más información',
            productAndRisk: 'Producto y riesgo',
            thirtyDays: '30 días',
            currentYear: 'Año actual',
            twelveMonths: '12 meses',
            liquidity: 'Liquidez',
            liquidityTooltip:
                'Es el plazo aproximado (en días hábiles) que toma el rescate del fondo desde que se solicita hasta que llega a tu caja. Cuando es por bolsa, el plazo son 2 días hábiles.',
            minAmount: 'Monto mínimo',
            perStockExchange: 'Por bolsa',
            variation: 'Variación de precio en el periodo {start} - {end}:',
            oneWeek: '1 semana',
            oneMonth: '1 mes',
            threeMonths: '3 meses',
            sixMonths: '6 meses',
            oneYear: '1 año',
            fiveYears: '5 años',
            moreDetails: 'Ver más detalles del fondo',
            yearlyRemunerationTooltip:
                'Cobro que hace la administradora por el servicio de gestionar la cartera del fondo. Está considerado en el valor cuota y la rentabilidad mostrada. Valor incluye IVA.',
            variableRemunerationTooltip:
                'Potencial cobro que realiza la administradora según el desempeño del fondo. Está considerado en el valor cuota y la rentabilidad mostrada. Valor incluye IVA.',
            periodTooltip:
                'Este fondo aún no tiene cifras rentabilidad para este período',
            searchPlaceholder: 'Busca por nombre o nemotécnico',
            modalParaph1:
                'Los clientes de BTG Pactual tienen acceso a la plataforma de fondos más completa y exclusiva del mercado.',
            modalParaph2:
                'Nuestra presencia global y relación con administradoras permite una oferta diversificada e integral para todas las carteras de inversión de nuestros clientes. Analizamos constantemente y en profundidad a los gestores de fondos en términos de equipo, enfoque de inversión, rentabilidad, riesgos y exposiciones.',
            modalParaph3:
                'La lista de Top Funds es el resultado de una cuidadosa selección de los fondos disponibles digitalmente en nuestra plataforma.',
            rbf: {
                title: 'Rebalanceo de fondos',
            },
        },
        fondos_internacionales: {
            title: 'Fondos mutuos internacionales',
            subtitle:
                '¿Quieres invertir fuera de Chile? Explora nuestra selección de fondos internacionales de las mejores administradoras del mundo',
            fundsOperations: 'Operaciones de fondos internacionales',
            fundsLearning: 'Aprende sobre fondos internacionales',
            help: 'Ayuda',
        },
        acciones: {
            title: 'Acciones',
            subtitle: 'Transa las principales empresas del mercado local.',
            sharesOperations: 'Operaciones de Acciones',
            sharesLearning: 'Aprende sobre Acciones',
            help: 'Ayuda',
            top: 'Mayores Alzas',
            flop: 'Mayores Bajas',
            moreTraded: 'Más Transadas',
            all: 'Todas',
            sectors: 'Sectores',
            recommendedPortfolio: 'Cartera recomendada',
            recommendedPortfolioDesc:
                'Selección de acciones hecha por el equipo de research de BTG Pactual.',
            seePorfolio: 'Ver cartera',
            actives: 'Activos',
            sector: 'Sector',
            currentPrice: 'Precio actual',
            tradedAmount: 'Monto transado',
            dailyVariation: 'Var. Diaria',
            monthlyVariation: 'Var. 30 días',
            noData: 'Sin datos',
            noInfoAvailable: 'No hay información disponible.',
            summary: {
                buyPrice: 'Compra',
                sellPrice: 'Venta',
                capMarket: 'Capitalización de mercado',
                openPrice: 'Precio apertura',
                minDay: 'Mínimo diario',
                min52W: 'Mínimo 52 semanas',
                accumulatedVolumeMoney: 'Monto transado',
                accumulatedVolumeMoneyMM: 'Monto transado',
                closePrice: 'Precio cierre (día anterior)',
                maxDay: 'Máximo diario',
                max52W: 'Máximo 52 semanas',
                variationToday: 'Variación diaria',
                variation30D: 'Variación 30 días',
                variationYTD: 'Variación año actual',
                variation12M: 'Variación 12 meses',
                amountTot: 'Valorización',
                nominal: 'Cantidad de acciones',
                nominalNotShare: 'Cantidad de cuotas',
                averagePrice: 'Precio prom. de compra',
                varPrice: 'Rentabilidad',
                varPriceMoney: 'Rentabilidad',
                varPriceMoneyUSD: 'Rentabilidad',
                portPercent: 'Porcentaje en Portafolio',
                dividend: 'Dividendos recibidos',
                amountTotUSD: 'Valorización en USD',
            },
            carteraBanner: {
                title: 'Cartera recomendada',
                subtitle:
                    'Selección de acciones hecha por el equipo de research de BTG Pactual.',
                link: 'Ver cartera',
            },
            cartera: {
                title: 'Cartera recomendada',
            },
        },
        dap: {
            title: 'Depósito a plazo',
            subtitle: 'Invierte con seguridad y buenas tasas en pesos y UF',
            dapOperations: 'Operaciones Depósito a plazo',
            dapLearning: 'Aprende sobre Depósito a plazo',
            help: 'Ayuda',
        },
        dolar: {
            title: 'Dólar',
            subtitle:
                'Compra y vende dólares en dos clicks. Úsalos para invertir o retirar tu cuenta corriente en dólares nacional.',
            usdOperations: 'Operaciones CV dólares',
            usdLearning: 'Aprende sobre dólares',
            help: 'Ayuda',
        },
    },
    fondos: {
        title: 'Fondos locales',
    },
    instrument: {
        nameType: 'Tipo',
        currency: 'Moneda',
        manager: 'Administradora',
        invEndTime: 'Hora límite orden aporte',
        rescEndTime: 'Hora límite orden rescate',
        rescTime: 'Liquidez rescate',
        aaEnabled: 'Mercado Secundario',
        remuneration: 'Remuneración anual max.',
        comisionRescate: 'Comisión por rescate anticipado',
        documents: 'Documentos',
        performance: 'Desempeño del fondo',
        info: 'Información del fondo',
        extraInfo: 'Información adicional',
        description: 'Descripción',
        series: 'Serie',
        price: 'Valor cuota',
        liquidity: 'Liquidez',
        cuota: '0 cuotas | 1 cuota | {n} cuotas',
        liquidityPeriod: '0 días | 1 día | {n} días',
        liquidityPeriodBolsa: 'Por bolsa',
        minAmount: 'Monto mínimo inversión',
        profit30D: 'Rentabilidad 30 días',
        profitYTD: 'Rentabilidad año actual',
        profit12M: 'Rentabilidad 12 meses',
        patrimonio: 'Patrimonio',
        windowClose: 'cierra',
        windowClosed: 'Cerrada',
        windowOpen: 'siguiente',
        windowDaily: 'Diaria',
        type: 'Tipo',
        money: 'Moneda',
        quotaValue: 'Valor cuota',
        nemotechnic: 'Nemotécnico bolsa',
        nemotechnicBloom: 'Nemotécnico Bloomberg',
        maxRemuneration: 'Remuneración anual max.',
        allFundsMaxRemuneration: 'Remuneración (TER / OGC)',
        tooltipRemAllFunds:
            'Ongoing Charges (OGC) son todos los gastos corrientes que tiene un fondo anualmente (incluyendo comisión de gestión, suscripción, intermediación, etc.). Total Expense Ratio (TER) incluye además los gastos no ordinarios, como las comisiones de éxito. Están considerados en el valor cuota y la rentabilidad mostrada.',
        variableRemuneration: 'Remuneración variable',
        closeTime: 'Horario cierre inversión',
        maxOperationalExpenses: 'Gastos operacionales máximos',
        finantialBenefits: 'Beneficios tributarios',
        geography: 'Geografía',
        fundInitDate: 'Fecha inicio fondo',
        research: {
            disclaimer:
                'Nada en estos reportes constituyen una indicación de que la estrategia de inversión o si las recomendaciones aquí citadas son adecuadas para el perfil del destinatario o apropiadas para las circunstancias individuales del destinatario, ni constituyen una recomendación personal.',
        },
    },
    saldos: {
        header: {
            title: 'Saldos y movimientos',
        },
        disponible: {
            title: 'Saldo disponible en {currency}',
            held: 'Retenido',
            tooltipHeld:
                'Es el saldo reservado para aportes y compras en proceso.',
            pending: 'Pendiente',
            tooltipPending:
                'Es el saldo por recibir de rescates, ventas y abonos en procesos.',
        },
        shortcuts: {
            contribute: 'Abonar',
            retrieve: 'Retirar',
            dollars: 'Dólares',
            cartolas: 'Cartolas',
            bankAccounts: 'Cuentas bancarias',
            help: 'Ayuda',
        },
        tabs: {
            movements: 'Movimientos',
            digitalOps: 'Operaciones digitales',
        },
        movimientos: {
            clp: 'En CLP',
            usd: 'En USD',
            type: 'Tipo',
            instrument: 'Instrumento',
            movementDate: 'Fecha movimiento',
            paymentDate: 'Fecha pago',
            amount: 'Monto',
            balance: 'Saldo',
            futureMovements: 'Movimientos futuros',
            historicMovements: 'Movimientos históricos',
            starterBalance: 'Saldo inicial al:',
            description: 'Descripción',
            comment: 'Movimiento',
            numberOperation: 'Folio',
            payments: 'Abonos',
            retirement: 'Cargos',
        },
        operaciones: {
            operation: 'Operación',
            description: 'Descripción',
            date: 'Fecha',
            amountOrPercent: 'Monto o %',
            status: 'Estado',
            at: 'A las',
            fee: 'cuota',
            fees: 'cuotas',
            share: 'acción',
            shares: 'acciones',
        },
    },
    dap: {
        title: 'Simulación',
        subtitle:
            'Simula cuánto podrías ganar con tu depósito a plazo e invierte.',
        simulateInvest:
            'Simula cuánto podrías gastar con tu depósito a plazo e invierte.',
        checkResult:
            'Revisa cuánto ganarías con tu depósito a plazo. Puedes aceptar la operación o volver a simular.',
        availableBalance: 'Saldo disponible:',
        inCLP: 'En pesos',
        inUF: 'En UF',
        investAmount: 'Monto a invertir',
        minAmount: 'Monto mínimo:',
        term: 'Plazo',
        day: 'días | día | días',
        nDays: '0 días | 1 día | {n} días',
        expiration: 'Vencimiento:',
        simulate: 'Simular',
        interestRates: 'Tasas de interés',
        interestInCLP: 'Tasa de interés en pesos',
        interestInUF: 'Tasa de interés en UF',
        daysOfTerm: 'Plazo en días',
        monthlyRate: 'Tasa mensual',
        yearlyRate: 'Tasa anual',
        footerSII: `La compra de un depósito a plazo a BTG Pactual Chile S.A Corredores de Bolsa emitido
            por el Banco BTG Pactual Chile S.A se informa al Servicio de Impuestos Internos en la
            Declaración Jurada 1932. Revisa los detalles de esta estructura de inversión con tu
            asesor tributario. Los depósitos no son anticipables ni renovables. Los depósitos a
            Plazo Fijo devengarán intereses solo hasta el día del vencimiento. Al vencimiento será
            abonado a tu cuenta.`,
        simulationResult: 'Resultado de tu simulación',
        insuficcientFunds:
            'No tienes saldo suficiente para invertir. Para continuar, debes cargar saldo en tu cuenta.',
        checkEstimation:
            'Revisa cuánto ganarías con tu depósito a plazo. Puedes aceptar la operación o volver a simular.',
        finalAmount: 'Monto final',
        profit: 'Ganancia',
        termRate: 'Tasa periodo',
        estExpiration: 'Vencimiento',
        simulateAgain: 'Volver a simular',
        accept: 'Aceptar operación',
        otherOptions: 'Otras opciones',
        compareProfits: 'Compara tus ganancias a distintos plazos',
    },
    transactions: {
        maintenanceMode: 'Plataforma en mantención',
        availableBalance: 'Saldo disponible en {currency}',
        availableAmount: 'Monto disponible',
        availableQuotas: 'Cuotas disponibles',
        availableShares: 'Acciones disponibles',
        quotasToSell: 'Cantidad de cuotas a rescatar',
        quotasSell: 'Cantidad de cuotas a vender',
        sharesToSell: 'Acciones a vender',
        notAvailable:
            'Tu cuenta no está habilitada para operar este producto. Comunícate con tu ejecutivo.',
        notCuenta4:
            'Por ahora no puedes realizar operaciones digitales en tu cuenta APV. Comunícate con tu ejecutivo.',
        notQualified: `Por normativa, solo inversionistas calificados pueden invertir en este instrumento.`,
        notQualifiedMoreInfo: 'Más información',
        fundClosedTitle: `Ventana cerrada`,
        fundClosed:
            'La ventana de {transaction} a este fondo está actualmente cerrada.',
        fundClosedDate: 'La siguiente fecha de apertura es el {date}.',
        unavailableProduct: 'Producto no disponible',
        contactChat: 'Escribir al chat',
        currentlyClosed:
            'Mercado actualmente cerrado. En este momento no podemos ingresar tu orden. Puedes comprar y vender por bolsa cuotas de fondos de lunes a viernes, entre {startTime} y {endTime} hrs.',
        sharesCurrentlyClosed: `Mercado actualmente cerrado. En este momento no podemos ingresar tu orden. Puedes comprar y vender acciones de lunes a viernes, entre {startTime} y {endTime} hrs.`,
        allFundsCurrentlyClosed: `Mercado actualmente cerrado. Puedes realizar aportes o rescates de fondos mutuos internacionales de lunes a viernes, entre {startTime} y {endTime} hrs.`,
        usdCurrentlyClosed: `Mercado actualmente cerrado. Puedes comprar y vender dólares de lunes a viernes entre {startTime} y {endTime} hrs.`,
        contributeBalance: 'Abonar más saldo',
        amountToInvest: 'Monto a invertir',
        amountToRescue: 'Monto a rescatar',
        percentToRescue: 'Porcentaje a rescatar',
        useAllBalance: 'Usar saldo disponible',
        percentRescue: 'Rescatar el {percent}',
        percentSell: 'Vender el {percent}',
        minInvestment: 'Inversión mínima',
        maxRemuneration: 'Remuneración máxima anual',
        terRemuneration: 'Remuneración (TER / OGC)',
        earlyRescueCommission: 'Comisión de rescate anticipado',
        minSale: 'Venta mínima',
        oneToThirtyDays: '1-30 días:',
        cancel: 'Cancelar',
        back: 'Atrás',
        close: 'Cerrar',
        next: 'Siguiente',
        contribute: 'aporte',
        retrieve: 'rescate',
        outOfProfileTitle: 'Tu perfil de inversionista no calza',
        anexoTitle: 'Tienes documentos pendientes de firma',
        outOfProfileDesc:
            'El producto solicitado no es adecuado a tu perfil {profile} según lo dispuesto en la Norma de Carácter General N°380 de la CMF.',
        outOfProfileAgreement: 'operar fuera de mi perfil de inversionista',
        outOfProfileInfo:
            'Calculamos tu perfil de inversionista según las respuestas que entregaste en la encuesta de perfil al momento de hacerte cliente. Si no estás conforme, puedes realizarla nuevamente.',
        anexo: 'Anexo A',
        anexoDesc:
            'Para continuar con la operación debes aceptar las condiciones del nuevo Anexo A del contrato de prestación de servicios.',
        anexoOutOfProfileDesc: `El producto solicitado no es adecuado a tu perfil {profile} según lo dispuesto
            en la Norma de Carácter General N°380 de la CMF. Para continuar debes firmar el
            nuevo Anexo a tu Contrato de Prestación de Servicios con las comisiones de los
            intrumentos fuera de perfil.`,
        anexoAgreement: 'las condiciones del nuevo Anexo A',
        agree: 'Acepto',
        and: 'y',
        youWillInvest: 'Vas a invertir',
        youWillSell: 'Vas a rescatar',
        youWillSellBolsa: 'Vas a vender',
        estRescue: 'Rescate estimado',
        in: 'en {name}',
        of: 'de {name}',
        account: 'Cuenta',
        entryDate: 'Fecha de ingreso',
        afterInvestBalance: 'Saldo después de invertir',
        moreThanBalance: 'Aporte mayor al saldo disponible',
        moreThanMax: 'Monto excede el máximo',
        lessThanMin: 'Aporte menor al mínimo',
        lessThanMinSell: 'Rescate menor al mínimo',
        lessThanMinWithdraw: 'Retiro menor al mínimo',
        balanceIsZero: 'Monto excede tu saldo disponible',
        notEqual: 'Monto no es el correspondiente',
        moreThanPercent: 'Monto corresponde a más del {percent}%',
        moreThanPercentNotification:
            'Si quieres rescatar más del 90% de tu posición, haz click en "Rescatar el 100%". Como el valor cuota puede variar entre que ingresas tu orden y ésta se liquida, si rescatas un monto muy cercano al total es posible que no se curse o quede un remanente de tu posición.',
        moreThanAmount: 'Aporte mayor al monto disponible',
        moreThanAmountSell: 'Rescate mayor al monto disponible',
        lessThanCommission: 'Venta menor a la comisión mínima',
        unavailableTerm: 'El plazo no está dentro del rango de días hábiles',
        infoLessThanCommission:
            'La cantidad ingresada equivale a un monto menor que la comisión mínima ({amount})',
        price: 'Precio',
        investBy: 'Invertir por',
        amount: 'Monto',
        quantity: 'Cantidad',
        peak: 'Mejor punta',
        limit: 'Límite',
        peakLabel: 'El mejor precio disponible es',
        peakTooltip:
            '¿Por qué es distinto al precio actual? Éste representa el precio al que se hizo la última transacción, mientras que las puntas son el precio al que alguien esta dispuesto a vender o comprar en este momento.',
        limitDesc: 'El precio límite debe estar entre {min} y {max}',
        lastPrice: 'Último precio {price}',
        purchase: 'Compra',
        sale: 'Venta',
        quota: '1 cuota',
        quotas: 'cuotas | cuota | cuotas',
        quotasShares: 'acciones | acción | acciones',
        quotaQuantity: 'Cantidad de cuotas',
        sharesQuantity: 'Cantidad de acciones',
        limitPrice: 'Precio límite',
        commissionAndIVA: 'Comisión + IVA',
        totalReservation: 'Total reserva',
        insufficientAmount: 'Monto insuficiente',
        higherThanLimit:
            'La mejor punta está fuera del rango de 5% del último precio. Ingresa un precio límite dentro del rango o inténtalo más tarde.',
        insufficientAmountInfo:
            'El monto ingresado no es suficiente para comprar 1 {measure} de {name} de valor {quota}, considerando una comisión de {commission}. Prueba con un monto más alto.',
        surpassMax: 'Monto supera el máximo digital',
        surpassMaxInfo:
            'El monto máximo de cada operación por bolsa es de {max}. Si quieres operar un monto mayor, ingresa varias transacciones o contáctanos por el chat.',
        orderValidity:
            'Las órdenes ingresadas tienen vigencia diaria. Si no se ejecutan al final del horario bursátil ({endTime} hrs) estas son canceladas automáticamente.',
        commission: 'Comisión de transacción',
        commissionTooltip:
            'Aplica el costo por transacción y costo mínimo estipulado en el Anexo A del Contrato de Condiciones Generales de Prestación de Servicios',
        minInvestAmount: 'Inversión mínima',
        minSellAmount: 'Monto mínimo de rescate',
        maxSellAmount: 'Máximo rescate por monto',
        liquidityPeriod: 'Plazo de liquidación',
        totalProfit: 'Total a recibir',
        day: '0 días | 1 día | {n} días',
        outOfSchedule:
            'Operación fuera de horario ({time} hrs). Te reservaremos el monto hoy y la orden será ingresada a primera hora del próximo día hábil.',
        finalAmountReminder:
            'Recuerda que el monto final a recibir es estimado. Esto se debe a que el valor cuota puede variar entre que ingresas tu orden y el gestor del fondo la confirma.',
        yes: 'Si',
        secondaryMarket: 'Mercado secundario',
        secondaryMarketTooltip:
            'Activa mercado secundario para comprar y vender cuotas por bolsa. La liquidez es en 2 días hábiles, pero revisa los precios y comisiones.',
        success: {
            title: 'Operación solicitada',
            invest: 'Invertiste',
            investLong: 'Ingresaste una orden de compra por',
            retrieve: 'Rescataste',
            sell: 'Vendiste',
            sellLong: 'Ingresaste una orden de venta por',
            summary: 'Resumen de la operación',
            name: 'en {name}',
            message: `Enviamos una copia de la solicitud a tu correo
                electrónico {email}. [if fuera de
                horario] Te notificaremos cuando la orden se
                ingrese. Puedes [cancelarla y] revisar el estado
                en la sección Operaciones digitales`,
            copyMsg:
                'Enviamos una copia de la solicitud a tu correo electrónico {email}.',
            copyMsgDollar:
                'Enviamos una copia de la solicitud a tu correo electrónico {email}. Puedes revisar el estado en la sección ',
            outOfScheduleMsg: 'Te notificaremos cuando la orden se ingrese.',
            statusMsg:
                'Puedes revisar su estado {extra} en la sección Operaciones digitales.',
            statusMsgSmall: 'Puedes revisar su estado {extra} en la sección',
            cancelableMsg: 'o cancelarla',
            bolsaNotification: 'Te notificaremos cuando la orden se ejecute.',
            link: `Ir a operaciones digitales`,
            linkSmall: 'Operaciones digitales',
            differentTotalMsg:
                'El monto final puede ser menor porque la cantidad de cuotas debe ser entera. El remanente será devuelto a tu caja',
            transactionDate: 'Fecha de ingreso',
            execDate: 'Fecha de ejecución',
            execDateTooltip:
                'Fecha estimada en que esta inversión debería aparecer en tu portafolio',
            liquidityDate: 'Fecha de liquidación',
            liquidityDateTooltip:
                'Fecha estimada en que el rescate estaría disponible en tu caja',
            estSell: 'Rescate estimado',
            total: 'Total reserva',
            accept: 'Aceptar',
            gotoHome: 'Ir a Inicio',
        },
        error: {
            notRequestedOperation: 'Operación no pudo ser solicitada',
            title: 'Hubo un error al procesar la operación',
            headline: 'en {instrument}',
            tryAgainLater:
                'Vuelve a intentarlo más tarde. Si el problema persiste o tienes dudas, contáctanos por el chat.',
            contactChat: 'Contactar al chat',
            close: 'Cerrar',
        },
    },
    moneda: {
        currentPrice: 'Precio actual',
        varInDay: 'Var. {n} días | Var. {n} día | Var. {n} días',
        sell: 'Vender',
        buy: 'Comprar',
        day: '{n} días | {n} día | {n} días',
        week: '{n} semanas | {n} semana | {n} semanas',
        month: '{n} meses | {n} mes | {n} meses',
        year: '{n} años | {n} año | {n} años',
        periodVariation: 'Variación de precio en el período {start} - {end}: ',
        tutorialTitle:
            'La forma más fácil y segura de comprar y vender dólares',
        realTimeMsg:
            'Invierte en tiempo real con un spread transparente y competitivo',
        withdrawalMsg:
            'Retíralos a tu cuenta corriente local en dólares y úsalos para lo que quieras',
        fundsInvestMsg:
            'Utilízalos para invertir en cientos de fondos locales e internacionales',
        backupMsg:
            'Con el respaldo del Banco de Inversión más grande de Latinoamérica',
        dollarsPurchase: 'Compra de dólares',
        dollarsSale: 'Venta de dólares',
        availableBalance: 'Saldo disponible',
        availableBalanceIn: 'Saldo disponible en {currency}',
        minAmount: 'Monto mínimo',
        maxAmount: 'Monto máximo',
        maxAmountTooltip:
            'El máximo para comprar o vender digitalmente es {amount}. Para montos mayores, contacta a tu ejecutivo o escríbenos por el chat.',
        amountToBuy: 'Monto a comprar',
        amountToSell: 'Monto a vender',
        amountIn: 'Monto en {currency}',
        youWillBuy: 'Vas a comprar',
        youWillSell: 'Vas a vender',
        totalToGet: 'Total a recibir',
        purchased: 'Compraste',
        sold: 'Vendiste',
        purchase: 'Compra',
        availableSoon:
            'En los próximos minutos verás los dólares disponibles en tu portafolio.',
        availableSoonSell:
            'En los próximos minutos verás el saldo disponible en tu caja.',
        purchaseRate: 'Tipo de cambio compra',
        saleRate: 'Tipo de cambio venta',
    },
    timer: {
        purchasePrice: 'Precio compra {currency}:',
        salePrice: 'Precio venta {currency}:',
        secondsLeft: 'Tienes {time} segundos para invertir',
        investTime: 'Tiempo para invertir:',
        second: '1 segundo',
        seconds: '{seconds} segundos',
    },
    common: {
        yes: 'Si',
        no: 'No',
        invest: 'Invertir',
    },
    token: {
        confirm: 'Confirmar',
        save: 'Guardar',
        dialog: {
            sms: 'Ingresa el código de 6 dígitos que te hemos enviado a tu celular',
            softtoken: 'Ingresa el código de 6 dígitos de Google Authenticator',
            noPhone:
                'Para confirmar la transacción, primero debes ingresar tu número de celular',
        },
        input: {
            placeholder: 'Código',
            error: 'El código ingresado es incorrecto',
        },
        timeout: '¿No recibiste el código?',
        resend: 'Reenviar',
        timer: '¿No recibiste el código? Podrás pedir un nuevo código en {time} segundos',
        title: 'Confirma la operación',
        noPhoneTitle: 'Ingresa tu número de celular',
    },
    monitor: {
        verDetalle: 'Ver detalle',
    },
    favorites: {
        alreadyFaved:
            'Ya tienes este instrumento agregado a una de tus listas. Selecciona otra para moverlo',
        add: {
            success: 'Instrumento agregado a tus favoritos.',
        },
    },
    chartArea: {
        businessDay: 'Día hábil',
        currentYear: 'Año actual',
        nDays: '{n} días | {n} día | {n} días',
        nWeeks: '{n} semanas | {n} semana | {n} semanas',
        nMonths: '{n} meses | {n} mes | {n} meses',
        nYears: '{n} años | {n} año | {n} años',
    },
    notifications: {
        title: 'Notificaciones',
        readAll: 'Marcar todas como leídas',
        empty: 'No tienes notificaciones.',
    },
    auth: {
        passwordRecover: 'Recuperar contraseña',
        newPassword: 'Crear nueva contraseña',
        enterDNI:
            'Para crear tu nueva contraseña, ingresa tu RUT y te enviaremos un código a tu correo electrónico.',
        enterCodeInMail:
            'Ingresa el código que te hemos enviado a tu correo registrado.',
        enterPassword:
            'Ingresa la contraseña temporal que enviamos a tu correo electrónico.',
        dni: 'RUT',
        sendCode: 'Enviar código',
        sentCode:
            'Hemos enviado a tu correo una contraseña temporal. Debes ingresarla en el siguiente paso y luego crear una nueva contraseña.',
        back: 'Volver',
        enterCode: 'Ingresar el código',
        recoverPassword:
            'Para restablecer contraseña, ingrese el código enviado a su correo electrónico.',
        code: 'Código',
        send: 'Enviar',
        passRequested: 'Restablecimiento de contraseña solicitada',
        howToSetPassword:
            'Para restablecer contraseña, ingresa al sitio web utilizando el código enviado a tu correo electrónico.',
        choosePassword: 'Escoge tu nueva contraseña',
        followRequirements: 'Por seguridad, crea una nueva contraseña.',
        requirements: 'Tu contraseña debe tener:',
        sixToEightChars: 'Tener de 6 a 8 caracteres',
        differentNumbers: 'No tener números repetidos o secuencias.',
        notBirthdate: 'No hacer referencia a tu día de nacimiento.',
        unmatchingPass: 'Contraseña no coincide',
        newPass: 'Nueva contraseña',
        repeatNewPass: 'Repite nueva contraseña',
        currentPass: 'Contraseña actual',
        tempPass: 'Contraseña temporal',
        continue: 'Continuar',
        changePass: 'Cambiar contraseña',
        passwordRecovered: 'Tu nueva contraseña ha sido creada con éxito.',
        defaultError:
            'Ocurrió un error. Por favor revise sus datos y vuelva a intentarlo.',
        wrongPassword: 'La contraseña ingresada es incorrecta.',
        wrongCode: 'El código ingresado es incorrecto.',
    },
    autoinvest: {
        title: 'AutoInvest',
        autopilot:
            'Tu inversión en piloto automático con los fondos gestionados de BTG Pactual.',
        differentRisk:
            'Recuerda que este fondo AutoInvest no coincide con tu perfil.',
        about: 'Conoce AutoInvest',
        specialists:
            'AutoInvest es un servicio en donde un equipo de especialistas de BTG Pactual se encarga por completo de tu inversión.',
        easy: 'Invertir es fácil cuando es automático',
        expertTeam:
            'A través de los fondos mutuos BTG Pactual Gestión, un equipo de expertos decide dónde invertir y qué hacer cada vez que los mercados cambian, manteniendo tu inversión siempre optimizada. Todo de forma automática.',
        fullDigital: 'Es 100% digital y más accesible de lo que imaginas',
        fullDigital1: 'Comienza a invertir desde $ 5.000.',
        fullDigital2: 'Comisión anual de sólo 1,0% IVA incluído (0,84% + IVA).',
        fullDigital3:
            'Rescata todo o parte de tu inversión en sólo 3 días hábiles.',
        fullDigitalBtn: 'Conoce quién va a hacer esto',
        bestInstruments: 'Los mejores instrumentos en un solo fondo',
        composedBy:
            'La cartera de inversión en AutoInvest esta compuesta por instrumentos locales e internacionales cuidadosamente elegidos, y tu accedes a ellos convenientemente a través de un solo fondo mutuo.',
        aboutOurProcess: 'Conoce nuestro proceso',
        yourProfile: 'Para tu perfil específico',
        yourProfileDesc:
            'Hay un fondo gestionado para cada inversionista, sean conservadores, moderados o agresivos. Cuenta con un servicio específico para tu perfil de riesgo personal.',
        historicalProfit: 'Ver rentabilidad histórica',
        specialistsWord: 'Palabra de especialista',
        specialistsWordDesc:
            'Todos los meses nuestro equipo de especialistas te cuenta los acontecimientos más relevantes del mercado, y cómo AutoInvest se adaptó a esas nuevas condiciones.',
        diversifiedInvest: 'Una inversión diversificada',
        diversifiedInvestDesc:
            'Los expertos saben que la diversificación es la forma de maximizar la rentabilidad y disminuir el riesgo. Descubre cómo están distribuidas las inversiones de los fondos mutuos BTG Pactual Gestión.',
        seeCurrentMonth: 'Ver composición del mes actual',
        distributed: 'Esta inversión está distribuida en {distribution}',
        category: '{n} categorías | 1 categoría | {n} categorías',
        // Características del producto
        productFeatures: 'Características del producto',
        name: 'Nombre',
        series: 'Serie',
        risk: 'Riesgo',
        minInvest: 'Inversión mínima',
        minInvestTT: 'pending',
        yearlyRemuneration: 'Remuneración anual',
        yearlyRemunerationTT: 'pending',
        liquidity: 'Liquidez',
        businessDay: '{n} días hábiles | 1 día hábil | {n} días hábiles',
        downloads: 'Descargas',
        // Conoce otros perfiles
        aboutProfiles: 'Conoce otros perfiles',
        aboutProfilesDesc:
            'Basado en tu perfil de inversionista, te recomendamos AutoInvest {risk}. Si quieres puedes conocer las alternativas para otros perfiles:',
        conservador: 'Conservador',
        moderado: 'Moderado',
        agresivo: 'Agresivo',
        conservadorDesc:
            'Priorizas mantener tu capital. Puedes tolerar algunos riesgos, aunque mínimos, con tal de obtener una rentabilidad baja pero relativamente estable.',
        moderadoDesc:
            'Tratas de balancear el obtener ganancias con preservar tu capital. Al invertir buscas tomar algunos riesgos, aunque controlados, con tal de obtener una rentabilidad razonable.',
        agresivoDesc:
            'Buscas activamente obtener ganancias usando tu capital. Arriesgas en tus inversiones y soportas períodos de volatilidad con tal de obtener altas rentabilidades.',
        learnAbout: 'Conocer AutoInvest con este perfil',
        // Empty state
        noProducts: 'No hay productos para tu perfil',
        checkOthers:
            'No existe AutoInvest para tu perfil Ultra conservador. Si quieres, puedes revisar el producto para otros perfiles de riesgo.',
        // Composition
        currentMonthComp: 'Composición del mes actual',
        compositionTitle: 'Composición del mes actual {fondo}',
        compositionDesc:
            'Cuenta con un equipo senior de especialistas, con amplia experiencia financiera y un sólido historial de resultados, trabajando juntos por varios años.',
        compositionCategories: '¿Qué son estas categorías?',
        compositionInfo:
            'La cartera de inversión en AutoInvest está compuesta por instrumentos locales e internacionales cuidadosamente elegidos y tu accedes a ellos convenientemente a través de un solo fondo mutuo. La composición cambia todos los meses considerando las proyecciones de nuestros especialistas.',
        // Conoce quién manejará tu inversión
        manageTitle: 'Conoce quién manejará tu inversión',
        manageDesc:
            'Cuenta con un equipo senior de especialistas, con amplia experiencia financiera y un sólido historial de resultados, trabajando juntos por varios años.',
        // Conoce nuestro proceso
        processTitle: 'Conoce nuestro proceso',
        processDesc:
            'Nuestro equipo sigue un meticuloso proceso para maximizar la rentabilidad de tu inversión:',
        // Conoce las categorías
        categoriesTitle: 'Conoce las categorías',
    },
    breadcrumb: {
        home: 'Inicio',
    },
    rules: {
        required: 'Campo requerido',
        requiredIf: 'Campo requerido',
        minCharacters:
            'Al menos {n} caracteres | Al menos {n} caracter | Al menos {n} caracteres',
        maxCharacters:
            'Máximo {n} caracteres | Máximo {n} caracter | Máximo {n} caracteres',
        lowercase: 'Al menos 1 letra minúscula',
        uppercase: 'Al menos 1 letra mayúscula',
        number: 'Al menos 1 número',
        symbol: "Al menos 1 símbolo entre {'@'} # {'$'} % : ; ( ) + _ = . , - ^ & *",
        coincidence: 'Tu contraseña no coincide',
        itMustToBeDifferent:
            'La nueva contraseña no debe ser igual a la contraseña actual',
        rut: 'RUT inválido',
        phone: 'Teléfono inválido',
        mail: 'Correo electrónico no es válido',
        moreThanPercentage: 'Máximo 100%',
    },
    investments: {
        empty: {
            title: 'Aún no tienes inversiones',
            description:
                'Invierte directamente desde el monitor o explora los productos que tenemos para ti.',
        },
    },
    quickLogin: {
        drawer: {
            title: '¡Qué bueno verte! </br> Inicia sesión para comenzar',
            subTitle:
                'Si eres cliente de Mercados en Línea, accede con la misma contraseña.',
            footer: 'BTG Pactual Chile S.A Corredores de Bolsa se reserva el derecho de rechazar la incorporación de una persona que solicita convertirse en cliente conforme a su Política de Aceptación de Clientes.',
            footerViewMore:
                'BTG Pactual Chile S.A. Corredores de Bolsa (la “Corredora”) se reserva el derecho de rechazar la incorporación de una persona que solicita convertirse en cliente, para efectos de contratar un determinado producto o servicio ofrecido por la Corredora, conforme al análisis de los antecedentes suministrados por el mismo solicitante, como de la información disponible en diversas fuentes oficiales de información que den cuenta de la existencia de morosidad en el sistema financiero, de la existencia de protestos, y de otros antecedentes de orden financiero o comercial que hagan previsible futuros incumplimientos de pago o incumplimientos contractuales. En dicho análisis, considerará también factores de riesgo relativos a lavado de activos y financiamiento del terrorismo, entre otros. En consecuencia, en base a dichos antecedentes e información disponible, la Corredora podrá decidir no contratar con dicho solicitante, rechazando la contratación de los productos y/o de los servicios que son objeto de la solicitud. Al respecto, y a expreso requerimiento escrito del interesado, la Corredora informará de las razones que justifican la negativa, sin posterior responsabilidad ni ulterior obligación para la Corredora de proceder a la contratación con el solicitante. Asimismo, la Corredora se reserva el derecho de modificar y actualizar su <a href={link} target="_blank" class="view-more--link">Política de Aceptación de Clientes</a>, de tiempo en tiempo, en consideración a nuevos requerimientos regulatorios, nuevos estándares de mercado, la incorporación de nuevos productos, de nuevos riesgos, y en general, de cualquier antecedente que, en su criterio, haga necesaria la modificación de dicha Política, todo lo cual será puesto a disposición de los interesados, clientes y público en general, en forma previa a la oferta de los productos y servicios que la Corredora ofrece.',
        },
    },
    register: {
        title: `Hazte cliente de BTG Pactual`,
        subtitle: `Tu cuenta es sin costo y 100% digital`,
        footer: `BTG Pactual Chile S.A Corredores de Bolsa se reserva
            el derecho de rechazar la incorporación de una
            persona que solicita convertirse en cliente conforme
            a su Política de Aceptación de Clientes.`,
        error: `Si ya eres cliente de BTG Pactual o de Mercados en Línea, inicia sesión con tu RUT y contraseña. Si necesitas más información comunícate con nosotros por el chat o en contactodigital{'@'}btgpactual.com.`,
    },
    login: {
        title: '¡Qué bueno verte! Inicia sesión para comenzar',
        subtitle:
            'Si eres usuario de Mercados en Línea, accede con la misma contraseña.',
    },
    loginStatus: {
        err: {
            '02': {
                kind: 'error',
                title: 'RUT y/o contraseña incorrecta',
                message: `RUT y/o contraseña incorrecta. Si no recuerdas tu contraseña, puedes  <a href="/forgot" title="Ir a recuperar contraseña">solicitar una nueva</a>`,
            },
            18: {
                kind: 'info',
                title: 'RUT y/o contraseña incorrecta',
                message: `RUT y/o contraseña incorrecta. Hemos enviado a tu correo una contraseña temporal`,
            },
            '06': {
                kind: 'error',
                title: 'Usuario bloqueado',
                message: `Usuario bloqueado. Por políticas de seguridad el RUT ha sido bloqueado. Para ingresar, contáctanos por el chat o escríbenos a contactodigital{'@'}btgpactual.com`,
            },
        },
    },
    profile: {
        breadcrumb: 'Mi perfil',
        share: {
            breadcrumb: 'Recomienda',
            title: 'Recomienda BTG Pactual',
            subtitle: 'Invita a tus amigos a invertir con BTG Pactual.',
            bannerTitle: '¿Disfrutando la experiencia BTG Pactual?',
            bannerDescription:
                'Invita a tus familiares y amigos a ser parte del mayor banco de inversiones de Latinoamérica.',
            shareDescription:
                'Copia el link personalizado y compártelo con tus amigos.',
            copied: 'Link copiado con éxito',
        },
        certificates: {
            title: 'Certificados tributarios',
            subtitle:
                'Encuentra los documentos necesarios para la declaración del año tributario vigente.',
            disclaimer:
                'La información contenida en estos certificados es confidencial y está dirigida exclusivamente a su titular. Cualquier uso, reproducción, divulgación o distribución por terceras personas que no sean sus titulares está estrictamente prohibida. BTG Pactual Chile no se responsabiliza por el uso indebido que se dé a ésta información por terceros no autorizados.',
            downloadAll: 'Descargar todos',
        },
        contracts: {
            title: 'Contratos y documentos',
            subtitle:
                'Revisa los contratos vigentes que tienes con BTG Pactual y firma los contratos pendientes.',
            signed: 'Firmados',
            pending: 'Pendientes',
            contracts: 'Contratos | Contrato | Contratos',
            documents: 'Documentos',
            download: 'Descargar',
            downloadAll: 'Descargar todos',
            signSelection: 'Firmar selección',
            downloadSelection: 'Descargar selección',
            sign: 'Firmar',
            emptyStateDynamic: 'No tienes {0} pendientes',
            emptyState: 'No tienes certificados tributarios',
            emptystate_desc: 'Por ahora, no hay documentos para mostrar.',
        },
        invest: {
            title: 'Perfil de inversionista',
            yourProfile: 'Tu perfil es',
            redo: 'Rehacer encuesta',
        },
        reports: {
            title: 'Cartolas y reportes',
            description: 'Selecciona qué documento quieres revisar.',
            enter: 'Acceder',
            item1: 'Cartolas mensuales',
            desc1: 'Descarga tus cartolas mensuales históricas y del mes en curso.',
            item2: 'Registro de partícipes',
            desc2: 'Revisa el detalle de tus movimientos de Fondos Mutuos.',
            item3: 'Condiciones de ejecución',
            desc3: 'Descarga los informes semanales de tus operaciones bursátiles.',
            item4: 'Cartolas personalizadas',
            desc4: 'Genera cartolas históricas para periodos personalizados.',
            item5: 'Posiciones históricas',
            desc5: 'Revisa tus saldos y posiciones en una fecha determinada.',
            item6: 'Movimientos históricos',
            desc6: 'Revisa el detalle de tus movimientos en un periodo determinado.',
            monthly: {
                title: 'Cartolas mensuales',
                description:
                    'Descarga tus cartolas mensuales históricas y del mes en curso.',
                prompt: 'Selecciona mes a consultar',
                account: 'Cuenta',
                year: 'Año',
                month: 'Mes a consultar',
                annex: 'Incluir anexo de custodia',
                download: 'Descargar',
                send: 'Enviar por mail',
                error: {
                    title: '¡Oh, algo falló!',
                    description: 'Por favor inténtelo nuevamente',
                },
                success: 'Cartola enviada por mail con éxito',
            },
        },
        personalData: {
            title: 'Datos personales',
            subtitle:
                'Verifica tus datos y confirma que tu información esté actualizada.',
            personalInfo: 'Información personal',
            edit: 'Editar',
            cancel: 'Cancelar',
            fullname: 'Nombre completo',
            dni: 'RUT',
            maritalStatus: 'Estado civil',
            nationality: 'Nacionalidad',
            birthdate: 'Fecha de nacimiento',
            contactInfo: 'Información de contacto',
            mobilePhone: 'Celular',
            telephone: 'Teléfono fijo',
            phone: 'Teléfono',
            email: 'Email',
            addresses: 'Direcciones',
            fiscalAddress: 'Dirección fiscal',
            legalAddress: 'Dirección legal',
            correspondenceAddress: 'Dirección de correspondencia',
            confirm: 'Confirmar',
            enterPassword: 'Para confirmar, ingresa tu contraseña',
            password: 'Contraseña',
            continue: 'Continuar',
            invalidPassword: 'Contraseña inválida',
            street: 'Calle',
            streetNumber: 'Número',
            complementType: 'Tipo de complemento',
            complement: 'Complemento',
            country: 'País',
            state: 'Comuna',
        },
        softtoken: {
            title: 'Activar Soft Token',
            subtitle:
                'Te permite usar un token aleatorio para autorizar operaciones en vez del código SMS a tu celular registrado.',
            form: {
                title: 'Estado Soft Token:',
                status: {
                    active: 'Activado',
                    inactive: 'Desactivado',
                },
                subtitle: {
                    active: 'Para realizar operaciones en el portal web o aplicación móvil de BTG Pactual, deberás ingresar el token mostrado en la aplicación Google Authenticator.',
                    inactive: 'Códigos de confirmación se enviarán a {phone}.',
                },
                steps: {
                    header: 'Para activar Soft Token solo debes seguir los siguientes pasos:',
                    download: {
                        header: 'Descarga la aplicación Google Authenticator en tu teléfono móvil para poder configurar el token.',
                        ios: 'Google Authenticator para IOS',
                        android: 'Google Authenticator para Android',
                    },
                    qr: {
                        header: 'Con la aplicación escanea el código QR.',
                        warning:
                            '¡Importante! Este QR es secreto, no lo compartas con nadie.',
                    },
                    token: {
                        header: 'Ingresa el código que se muestra en la aplicación Google Authenticator para terminar la configuración.',
                    },
                },
                error: 'Código incorrecto',
                button: {
                    activate: 'Activar soft token',
                    deactivate: 'Desactivar soft token',
                },
            },
        },
        notifications: {
            title: 'Preferencias de notificaciones',
            subtitle:
                'Elige qué tipo de notificaciones push quieres recibir en tu celular.',
            info: 'Las notificaciones de seguridad seguirán enviándose normalmente.',
            toast: {
                success: 'Cambios aplicados con éxito',
                error: 'Hubo un error, por favor inténtalo nuevamente',
            },
        },
        report_configuration: {
            title: 'Contraseña y periodicidad de cartola',
            subtitle:
                'Elige la frecuencia para recibir tu cartola en PDF en tu correo electrónico, y la contraseña para abrirla.',
        },
        change_password: {
            title: 'Cambiar contraseña',
            subtitle:
                'Puedes cambiar tu clave de acceso al portal de inversiones de BTG Pactual. Aplica para su versión web y aplicación móvil.',
            submit: 'Actualizar',
            error: {
                passwordWrong: 'La contraseña actual ingresada no coincide',
                authError: 'Hubo un error, por favor intentalo nuevamente',
            },
        },
        accountSelection: {
            breadcrumb: 'Cambiar cuenta',
            title: 'Selecciona la cuenta a acceder',
            account: 'Cuenta y nombre',
            segment: 'Segmento',
            type: 'Tipo',
            access: 'Acceder',
            naturalPerson: 'Persona natural',
            legalEntity: 'Persona jurídica',
            success: 'Tu cuenta ha sido cambiada con éxito',
            error: '¡Oh, algo falló!',
            tryAgain: 'Por favor, reinténtalo.',
            noAccounts: 'Sin cuentas',
            unavailableAccounts: 'No hay cuentas disponibles.',
            currency: 'Moneda',
        },
        account: {
            title: 'Cuentas bancarias',
            configure:
                'Configura hasta dos cuentas para realizar retiros desde tu cuenta de inversiones.',
            reminder:
                'Por seguridad, solo puedes registrar cuentas bancarias que estén asociadas a tu RUT',
            number: 'Cuenta N°{n}',
            delete: 'Eliminar',
            bank: 'Banco',
            type: 'Tipo',
            currency: 'Moneda',
            accountNumber: 'Número de cuenta',
            addAccount: 'Agregar cuenta',
            targetAccount: 'Cuenta de destino BTG Pactual',
            selectedAccount:
                'La cuenta seleccionada es la predeterminada para reconocer futuros abonos vía transferencia electrónica.',
            account: 'Cuenta',
            update: 'Actualizar',
            accountAdded: 'Cuenta agregada con éxito',
            accountDeleted: 'Tu cuenta ha sido eliminada con éxito',
            accountUpdated: 'Cuenta actualizada con éxito',
            errorMsg: 'Hubo un error. Por favor inténtelo nuevamente',
            empty: {
                title: 'No tiene cuentas bancarias registradas',
                desc: 'Agrega cuentas en Pesos o Dólares para realizar retiros desde tu cuenta en BTG Pactual.',
            },
        },
    },
    documents: {
        anexoA: {
            error: {
                title: 'Hubo un error al descargar el documento. ',
                message:
                    'Vuelve a intentarlo más tarde. Si el problema persiste o tienes dudas, contáctanos por el chat.',
            },
        },
    },
    alerts: {
        detail: {
            variacionPercent: 'Variación %',
            price: 'Último Precio',
        },
        error: {
            maxPrice: 'El precio máximo no puede ser menor al precio lista.',
            minPrice: 'El precio mínimo no puede ser mayor al precio lista.',
            equalZero: 'Cantidad no puede ser menor o igual a 0.',
        },
        add: {
            success: 'Instrumento agregado a tus Alertas.',
        },
    },
    obol: {
        rut: {
            attemptError:
                'Superaste el límite de intentos en las preguntas de autenticación. Vuelve a iniciar sesión. | Una o más respuestas fueron incorrectas. Te queda un intento para responder correctamente. | Una o más respuestas fueron incorrectas. Te quedan { count } intentos para responder correctamente.',
        },
    },
    withdraw: {
        title: 'Retirar saldo',
        selectAccount: 'Selecciona la cuenta bancaria a la que quieres retirar',
        notification: `Si retiras antes de las 14:00 de un día hábil, el saldo estará disponible en tu cuenta bancaria el mismo día. En otro caso, lo verás al día hábil siguiente.`,
        noAccounts: {
            title: `No tiene cuentas bancarias registradas`,
            description: `Agrega cuentas en Pesos o Dólares para realizar retiros desde tu cuenta en BTG Pactual.`,
            action: `Agregar cuenta`,
        },
        commissionsWarning: {
            title: `Importante`,
            message: `Asegúrate de conocer las comisiones de tu banco por recibir dólares desde el extranjero. BTG Pactual no realiza ningún cobro por esta transacción.`,
        },
        notAvailable: {
            title: `Retiro no habilitado`,
            message: `Tu cuenta no está habilitada para ejecutar retiros. Comunícate con tu ejecutivo.`,
        },
        debitAccount:
            'Si retiras antes de las 14:00 de un día hábil, el saldo estará disponible en tu cuenta bancaria el mismo día. En otro caso, lo verás al día hábil siguiente. <br/><br/> Para cuentas vista debes sumar un día hábil adicional.',
    },
    pay: {
        title: 'Abonar saldo',
        notAvailable: {
            title: 'Abono no habilitado',
            message:
                'Tu cuenta no está habilitada para ejecutar abonos. Comunícate con tu ejecutivo.',
        },
    },
    ficha: {
        fields: {
            P_NACIONCONYUGE_CLIENTE: 'Nacionalidad del cónyuge',
            P_APEPATCONYUGE_CLIENTE: 'Apellido paterno del cónyuge',
            P_NOMBRECONYUGE_CLIENTE: 'Nombre del cónyuge',
            P_APEMATCONYUGE_CLIENTE: 'Apellido materno del cónyuge',
            P_RUTCONYUGE_CLIENTE: 'RUT del cónyuge',
            P_TELEFONO_CLIENTE: 'Teléfono',
            P_MAIL_CLIENTE: 'Correo electrónico',
            P_NTL_NUM: 'Celular',
            P_TIPO_DIRECCION_CALLEFISCAL: 'Tipo de dirección fiscal',
            P_DIRECFISCAL_CLIENTE: 'Dirección fiscal',
            P_NUMERO_DIRECCION_FISCAL: 'Número de dirección fiscal',
            P_DEPTODIRFISCAL_CLIENTE: 'Complemento fiscal',
            P_TIPO_DEPTO_FISCAL: 'Tipo de complemento fiscal',
            P_COMUNA_FISCAL: 'Comuna fiscal',
            P_PAIS_FISCAL: 'País fiscal',
            P_DF2_NIF_TAX: 'NIF fiscal',
            P_TIPO_DIRECCION_CLIENTE: 'Tipo de dirección legal',
            P_DIRECCION_CLIENTE: 'Dirección legal',
            P_NUMERO_DIRECCION: 'Número de dirección legal',
            P_NUMERO_DEPTO_DIR: 'Complemento legal',
            P_TIPO_DEPTO_DIR: 'Tipo de complemento legal',
            P_COMUNA_CLIENTE: 'Comuna legal',
            P_PAIS_CLIENTE: 'País legal',
            P_TIPO_DIRECCION_CORRESP: 'Tipo de dirección correspondencia',
            P_DIRECCORRESP_CLIENTE: 'Dirección de correspondencia',
            P_NUMERO_DIRECCION_CORRESP:
                'Número de dirección de correspondencia',
            P_NUMERO_DEPTO_CORRESP: 'Complemento correspondencia',
            P_TIPO_DEPTO_CORRESP: 'Tipo de complemento de correspondencia',
            P_COMUNA_CORRESP: 'Comuna de correspondencia',
            P_PAIS_CORRESP: 'País de correspondencia',
            P_CLI_CLASIFICADO_PEP: 'Check PEP',
            P_CLI_VINCULO_PEP: 'Típo de vínculo PEP',
            P_CLI_RELACIONADO_PEP: 'Primer nombre PEP',
            P_CLI_RELACIONADO_PEP_APE_PAT: 'Apellido paterno PEP',
            P_CLI_RELACIONADO_PEP_RUT: 'RUT PEP',
            P_DF1_DOMFISCAL: 'País de domicilio fiscal',
            P_DF2_NIF: 'NIF',
            P_CIU_OTROPAIS_CUAL: 'Países en los que tienes residencia',
            P_PER_RESID_OPAIS_CUAL:
                'Países en los que tienes un permiso de residencia',
            P_RUT_DUENO_FONDOS: 'RUT del dueño de los fondos',
            P_SEGUNDO_DUENO_FONDOS: 'Segundo nombre  del dueño de los fondos',
            P_APE_MATER_DUENO_FONDOS:
                'Apellido materno del dueño de los fondos',
            P_NOMBRE_DUENO_FONDOS: 'Nombre del dueño de los fondos',
            P_APE_PATER_DUENO_FONDOS:
                'Apellido paterno del dueño de los fondos',
            P_PATRIMONIO_TOTAL_EST: 'Patrimonio total',
            P_TOTAL_ACTIVO_ESTIM_PJ: 'Total activos',
            P_RENTA_TOTAL_ANUAL: 'Renta total',
            P_TOTAL_PASIVOS: 'Total pasivos',
            P_OCUPACION_PRINCIPAL: 'Ocupación principal',
            P_ACT_ECO_EMPRESA: 'Actividad de la empresa',
            P_NOMBREEMPRESA: 'Nombre de la empresa',
            P_POCEN_PARTICIPACION: 'Porcentaje de participación',
            P_OTRA_OCUPACION: 'Cargo u ocupación',
            P_PAIS_NACIMIENTO: 'País de nacimiento',
            P_PROFESIONACTIVIDAD_CLIENTE: 'Profesión',
            P_ESTADO_CIVIL: 'Estado civil',
            P_NOMBRE_RAZONSOCIAL: 'Primer nombre',
            P_APATERNO_CLIENTE: 'Apellido paterno',
            P_NACIONALIDAD_CLIENTE: 'Nacionalidad',
            P_GIRO_COMERCIAL: 'Giro comercial',
            P_REGIMEN_MATRIMONIAL: 'Régimen patrimonial',
            P_DF3_DOMFISCAL: 'Otro país de domicilio fiscal (1)',
            P_DF3_DOMFISCAL2: 'Otro país de domicilio fiscal (2)',
            P_DF3_DOMFISCAL3: 'Otro país de domicilio fiscal (3)',
            P_DF4_NIF: 'Otro NIF fiscal (1)',
            P_DF4_NIF2: 'Otro NIF fiscal (2)',
            P_DF4_NIF3: 'Otro NIF fiscal (3)',
        },
    },
    ncg380: {
        fields: {
            P_RUT_CLIENTE: 'RUT',
            P_SEGUNDO_NOMBRE: 'Segundo nombre',
            P_AMATERNO_CLIENTE: 'Apellido materno',
            P_NACIONALIDAD_CLIENTE: 'Nacionalidad',
            P_NOMBRE_RAZONSOCIAL: 'Primer Nombre',
            P_APATERNO_CLIENTE: 'Apellido paterno',
            P_ESTADO_CIVIL: 'Estado Civil',
            P_PROFESIONACTIVIDAD_CLIENTE: 'Profesión',
            P_RUTCONYUGE_CLIENTE: 'RUT cónyuge',
            P_SEGNOMCONYUGE_CLIENTE: 'Segundo nombre cónyuge',
            P_APEMATCONYUGE_CLIENTE: 'Apellido materno cónyuge',
            P_NACIONCONYUGE_CLIENTE: 'Nacionalidad cónyuge',
            P_NOMBRECONYUGE_CLIENTE: 'Primer Nombre cónyuge',
            P_APEPATCONYUGE_CLIENTE: 'Apellido paterno cónyuge',
            P_RUT_PERAUTORD: 'RUT persona autorizada 1',
            P_SEGNOMBRE_PERAUTORD: 'Segundo nombre persona autorizada 1',
            P_AMATERNO_PERAUTORD: 'Apellido materno persona autorizada 1',
            P_NACIONALIDAD_PERAUTORD: 'Nacionalidad persona autorizada 1',
            P_NOMBRE_PERAUTORD: 'Primer Nombre persona autorizada 1',
            P_APATERNO_PERAUTORD: 'Apellido paterno persona autorizada 1',
            P_RUT_PERAUTORD2: 'RUT persona autorizada 2',
            P_SEGNOMBRE_PERAUTORD2: 'Segundo nombre persona autorizada 2',
            P_AMATERNO_PERAUTORD2: 'Apellido materno persona autorizada 2',
            P_NACIONALIDAD_PERAUTORD2: 'Nacionalidad persona autorizada 2',
            P_NOMBRE_PERAUTORD2: 'Primer Nombre persona autorizada 2',
            P_APATERNO_PERAUTORD2: 'Apellido paterno persona autorizada 2',
            P_RUT_PERAUTORD3: 'RUT persona autorizada 3',
            P_SEGNOMBRE_PERAUTORD3: 'Segundo nombre persona autorizada 3',
            P_AMATERNO_PERAUTORD3: 'Apellido materno persona autorizada 3',
            P_NACIONALIDAD_PERAUTORD3: 'Nacionalidad persona autorizada 3',
            P_NOMBRE_PERAUTORD3: 'Primer Nombre persona autorizada 3',
            P_APATERNO_PERAUTORD3: 'Apellido paterno persona autorizada 3',
            P_TELEFONO_CLIENTE: 'Teléfono',
            P_MAIL_CLIENTE: 'Mail',
            P_TELEFONOCONYUGE_CLIENTE: 'Teléfono cónyuge',
            P_MAILCONYUGE_CLIENTE: 'Mail cónyuge',
            P_TELEFONO_PERAUTORD: 'Teléfono persona autorizada 1',
            P_MAIL_PERAUTORD: 'Mail persona autorizada 1',
            P_TELEFONO_PERAUTORD2: 'Teléfono persona autorizada 2',
            P_MAIL_PERAUTORD2: 'Mail persona autorizada 2',
            P_TELEFONO_PERAUTORD3: 'Teléfono persona autorizada 3',
            P_MAIL_PERAUTORD3: 'Mail persona autorizada 3',
            P_BANCO_CLIENTECDEB: 'Banco cuenta bancaria',
            P_BANCO_CLIENTE: 'Banco cuenta bancaria 2',
            P_TIPOCUENTA_BANCO_CDEB: 'Tipo de cuenta bancaria',
            P_TIPOCUENTA_BANCO: 'Tipo de cuenta bancaria 2',
            P_CTACTE_CLIENTECDEB: 'Número de cuenta bancaria',
            P_CTACTE_CLIENTE: 'Número de cuenta bancaria 2',
            P_MONEDA_CTACTE2: 'Divisa cuenta bancaria',
            P_MONEDA_CTACTE: 'Divisa cuenta bancaria 2',
            P_RUT_EMPLEADOR: 'RUT empleador',
            P_NOMBRE_EMPLEADOR: 'Nombre empleador',
            P_PAIS_CLIENTE: 'País',
            P_COMUNA_CLIENTE: 'Comuna',
            P_TIPO_DIRECCION_CLIENTE: 'Tipo de dirección',
            P_DIRECCION_CLIENTE: 'Dirección',
            P_NUMERO_DIRECCION: 'Número de dirección',
            P_TIPO_DIRECCION_COMPLEMENTO_CL: 'Tipo de complemento',
            P_NUMERO_DEPTO_DIR: 'Complemento de dirección',
            P_PAISCONYUGE_CLIENTE: 'País cónyuge',
            P_COMUNACONYUGE_CLIENTE: 'Comuna cónyuge',
            P_TIPODIRCONYUGE_CLIENTE: 'Tipo de dirección cónyuge',
            P_DIRECCIONCONYUGE_CLIENTE: 'Dirección cónyuge',
            P_NUMDIRCONYUGE_CLIENTE: 'Número de dirección cónyuge',
            P_DEPTODIRCONYUGE_CLIENTE: 'Tipo de complemento cónyuge',
            EP_DEPTODIRCONYUGE_CLIENTE: 'Complemento de dirección cónyuge',
            P_PAIS_PERAUTORD: 'País persona autorizada 1',
            P_COMUNA_PERAUTORD: 'Comuna persona autorizada 1',
            P_TIPODIR_PERAUTORD: 'Tipo de dirección persona autorizada 1',
            P_DESCDIR_PERAUTORD: 'Dirección persona autorizada 1',
            P_NUMDIR_PERAUTORD: 'Número de dirección persona autorizada 1',
            P_TIPO_DIRECCION_COMPLEMENTO_AUT:
                'Tipo de complemento persona autorizada 1',
            P_DEPTODIR_PERAUTORD:
                'Complemento de dirección persona autorizada 1',
            P_PAIS_PERAUTORD2: 'País persona autorizada 2',
            P_COMUNA_PERAUTORD2: 'Comuna persona autorizada 2',
            P_TIPODIR_PERAUTORD2: 'Tipo de dirección persona autorizada 2',
            P_DESCDIR_PERAUTORD2: 'Dirección persona autorizada 2',
            P_NUMDIR_PERAUTORD2: 'Número de dirección persona autorizada 2',
            P_TIPO_DIRECCION_COMPLEMENTO_AUT2:
                'Tipo de complemento persona autorizada 2',
            P_DEPTODIR_PERAUTORD2:
                'Complemento de dirección persona autorizada 2',
            P_PAIS_PERAUTORD3: 'País persona autorizada 1',
            P_COMUNA_PERAUTORD3: 'Comuna persona autorizada 1',
            P_TIPODIR_PERAUTORD3: 'Tipo de dirección persona autorizada 1',
            P_DESCDIR_PERAUTORD3: 'Dirección persona autorizada 1',
            P_NUMDIR_PERAUTORD3: 'Número de dirección persona autorizada 1',
            P_TIPO_DIRECCION_COMPLEMENTO_AUT3:
                'Tipo de complemento persona autorizada 1',
            P_DEPTODIR_PERAUTORD3:
                'Complemento de dirección persona autorizada 1',
            P_RELACION_INTERMEDIARIO: 'Relación con el banco',
            P_RELCONYUGE_CORREDOR: 'Relación del cónyuge con el banco',
            P_RELCORREDOR_PERAUTORD:
                'Relación de persona autorizada 1 con el banco',
            P_RELCORREDOR_PERAUTORD2:
                'Relación de persona autorizada 2 con el banco',
            P_RELCORREDOR_PERAUTORD3:
                'Relación de persona autorizada 3 con el banco',
        },
    },
    simulatedPortfolio: {
        add: {
            title: 'Agregar a Portafolio',
            success: 'Instrumento agregado a tus Simulados.',
        },
        edit: { title: 'Editar Portafolio' },
    },
    agency: {
        title: 'Asesoría y contacto',
        shortTitle: 'Asesoría',
        subtitle: '¿Tienes dudas? Habla con nuestros expertos.',
        tabs: {
            pending: 'Solcitudes pendientes',
            history: 'Historial de solicitudes',
        },
    },
};
