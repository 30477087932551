const ALPHA = false;
const routes = [
    {
        path: '/',
        name: 'home',
        redirect: { name: 'portfolio' },
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/pages/auth/LoginPage.vue'),
        meta: {
            // requiresAnon: true, // Disabled until we fix https://github.btgpactual.cl/Digital/Digital-Web/pull/619
            title: `Ingresa a tu cuenta de BTG Pactual | Inversiones Digitales`,
            description: `Invierte en acciones, fondos, dólares y depósitos a plazo con el mayor banco de inversión de Latinoamérica`,
        },
    },
    {
        path: '/forgot',
        name: 'forgot',
        component: () => import('@/pages/auth/LoginPage.vue'),
        meta: {
            requiresAnon: true,
        },
    },
    {
        path: '/logout',
        name: 'logout',
        component: () => import('@/pages/auth/LogoutPage.vue'),
    },
    {
        path: '/forgot',
        name: 'forgot',
        component: () => import('@/pages/auth/RecoverPage.vue'),
        props: (route) => ({
            loginRut: route.query.loginRut,
        }),
        meta: {
            requiresAnon: true,
        },
    },
    {
        path: '/change-password',
        name: 'change-password',
        component: () => import('@/pages/auth/ChangePassPage.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/signup',
        name: 'register',
        component: () => import('@/pages/auth/RegisterPage.vue'),
        meta: {
            requiresAnon: true,
            title: `Hazte cliente | BTG Pactual`,
            description: `Abre tu cuenta de inversión sin costo y 100% online. Comienza a invertir en minutos con el mayor banco de inversión de Latinoamérica`,
        },
    },
    {
        path: '/onboarding',
        name: 'onboarding',
        meta: { requiresObol: true },
        component: () => import('@/pages/onboarding/OnBoardingPage.vue'),
    },
    {
        path: '/pre-onboarding',
        name: 'pre-onboarding',
        component: () => import('@/pages/onboarding/PreOnboardingPage.vue'),
    },
    {
        path: '/account-setup',
        name: 'account-setup',
        meta: { requiresObol: true },
        component: () => import('@/pages/onboarding/AccountSetupPage.vue'),
    },
    {
        path: '/portfolio',
        name: 'portfolio',
        component: () => import('@/pages/portfolio/IndexRouter.vue'),
        meta: { requiresAuth: true },
        props: (route) => ({
            passwordChangedPopup: route.query.passwordChangedPopup,
            accountChangedPopup: route.query.accountChangedPopup,
            successFicha: route.query.successFicha,
            successNCG380: route.query.successNCG380,
        }),
    },
    {
        path: '/portfolio-bank',
        name: 'portfolio-bank',
        component: () => import('@/pages/portfolio/IndexRouterBank.vue'),
        meta: { requiresAuth: true },
        props: (route) => ({
            passwordChangedPopup: route.query.passwordChangedPopup,
            accountChangedPopup: route.query.accountChangedPopup,
        }),
    },
    {
        path: '/saldos-y-movimientos',
        name: 'saldos-y-movimientos',
        component: () => import('@/pages/saldos-y-movimientos/IndexPage.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/saldos-y-movimientos/operaciones-digitales',
        name: 'operaciones-digitales',
        component: () => import('@/pages/saldos-y-movimientos/IndexPage.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/productos-de-inversion',
        name: 'productos-de-inversion',
        component: () => import('@/pages/productos-de-inversion/IndexPage.vue'),
        meta: {
            requiresAuth: ALPHA,
            title: `Productos de inversión | BTG Pactual`,
            description: `Invierte en acciones, dólares, depósitos a plazo y fondos locales e internacionales con BTG Pactual, el mayor banco de inversión de Latinoamérica`,
        },
    },
    {
        path: '/productos-de-inversion/fondos-y-etfs-locales/rebalanceo-de-fondos',
        name: 'rbf',
        meta: { requiresAuth: true },
        component: () => import('@/pages/rbf/IndexPage.vue'),
    },
    {
        path: '/productos-de-inversion/fondos-y-etfs-locales',
        name: 'fondos-y-etfs-locales',
        component: () =>
            import(
                '@/pages/productos-de-inversion/fondos-y-efts-locales/IndexPage.vue'
            ),
        meta: {
            requiresAuth: ALPHA,
            title: `Invierte en fondos mutuos, fondos de inversión y ETF locales | BTG Pactual`,
            description: `Accede a fondos de las principales administradoras chilenas con BTG Pactual. La plataforma abierta de fondos más completa del mercado`,
        },
        children: [
            {
                path: ':tab?',
                name: 'fondos-y-etfs-locales-type',
                component: () =>
                    import(
                        '@/pages/productos-de-inversion/fondos-y-efts-locales/IndexPage.vue'
                    ),
            },
        ],
    },
    {
        path: '/productos-de-inversion/fondos-y-etfs-internacionales',
        name: 'fondos-y-etfs-internacionales',
        component: () =>
            import(
                '@/pages/productos-de-inversion/fondos-y-etfs-internacionales/IndexPage.vue'
            ),
        meta: {
            requiresAuth: ALPHA,
            title: `Invierte en fondos internacionales | BTG Pactual`,
            description: `Accede a cientos de fondos domiciliados en el extranjero con BTG Pactual. La red de fondos extranjeros más grande del mundo`,
        },
        children: [
            {
                path: ':tab?',
                name: 'fondos-y-etfs-internacionales-type',
                component: () =>
                    import(
                        '@/pages/productos-de-inversion/fondos-y-etfs-internacionales/IndexPage.vue'
                    ),
            },
        ],
    },
    {
        path: '/productos-de-inversion/acciones',
        name: 'acciones',
        component: () =>
            import('@/pages/productos-de-inversion/acciones/IndexPage.vue'),
        meta: {
            requiresAuth: ALPHA,
            title: `Invierte en acciones | BTG Pactual`,
            description: `Compra y vende acciones chilenas 100% online con BTG Pactual. Invierte informado con análisis, recomendaciones y datos financieros`,
        },
        children: [
            {
                path: ':tab?',
                name: 'acciones-type',
                component: () =>
                    import(
                        '@/pages/productos-de-inversion/acciones/IndexPage.vue'
                    ),
            },
        ],
    },
    {
        path: '/productos-de-inversion/acciones/cartera-recomendada',
        name: 'cartera',
        component: () =>
            import(
                '@/pages/productos-de-inversion/acciones/RecommendedWallet.vue'
            ),
        meta: {
            requiresAuth: ALPHA,
            title: `Cartera recomendada de acciones | BTG Pactual`,
            description: `Conoce e invierte en nuestra cartera recomendada de acciones chilenas. Rentabilidad de 26,4% durante 2023, superando el 17,8% del Índice S&P IPSA.`,
        },
    },
    {
        path: '/productos-de-inversion/dap',
        name: 'dap',
        meta: {
            requiresAuth: ALPHA,
            title: `Simula e invierte online en Depósitos a Plazo | BTG Pactual`,
            description: `Invierte en depósitos a plazo en pesos o UF con BTG Pactual. Una opción segura y con atractivas tasas de interés`,
        },
        component: () =>
            import('@/pages/productos-de-inversion/dap/IndexPage.vue'),
    },
    {
        path: '/productos-de-inversion/dolar',
        name: 'dolar',
        meta: {
            requiresAuth: ALPHA,
            title: `Compra y venta de dólares online | BTG Pactual`,
            description: `Invierte en dólares de forma 100% digital, con un tipo de cambio transparente y competitivo. Úsalos para ahorrar, invertir o retirar a tu cuenta bancaria`,
        },
        component: () =>
            import('@/pages/productos-de-inversion/dolar/IndexPage.vue'),
    },
    {
        path: '/productos-de-inversion/detail/:type?/:slug',
        name: 'productos-de-inversion-detail',
        meta: { requiresAuth: ALPHA },
        component: () =>
            import('@/pages/productos-de-inversion/DetailPage.vue'),
        props: true,
    },
    {
        path: '/asesoria-y-contacto',
        name: 'asesoria-y-contacto',
        component: () => import('@/pages/asesoria-y-contacto/IndexPage.vue'),
        //TODO: Uncomment meta: { requiresAuth: true },
    },
    {
        path: '/autoinvest/management',
        name: 'autoinvest-management',
        meta: {
            requiresAuth: ALPHA,
            title: `AutoInvest | BTG Pactual`,
            description: `Invierte de forma automática según tu perfil y deja que nuestros expertos se encarguen. AutoInvest combina lo mejor de varios fondos en un solo instrumento`,
        },
        component: () => import('@/pages/autoinvest/ManagementPage.vue'),
    },
    {
        path: '/autoinvest/process',
        name: 'autoinvest-process',
        meta: {
            requiresAuth: ALPHA,
            title: `AutoInvest | BTG Pactual`,
            description: `Invierte de forma automática según tu perfil y deja que nuestros expertos se encarguen. AutoInvest combina lo mejor de varios fondos en un solo instrumento`,
        },
        component: () => import('@/pages/autoinvest/ProcessPage.vue'),
    },
    {
        path: '/autoinvest/composition/:risk',
        name: 'autoinvest-composition',
        meta: {
            requiresAuth: ALPHA,
            title: `AutoInvest | BTG Pactual`,
            description: `Invierte de forma automática según tu perfil y deja que nuestros expertos se encarguen. AutoInvest combina lo mejor de varios fondos en un solo instrumento`,
        },
        component: () => import('@/pages/autoinvest/CompositionPage.vue'),
        props: true,
    },
    {
        path: '/autoinvest/composition/categories',
        name: 'autoinvest-categories',
        meta: {
            requiresAuth: ALPHA,
            title: `AutoInvest | BTG Pactual`,
            description: `Invierte de forma automática según tu perfil y deja que nuestros expertos se encarguen. AutoInvest combina lo mejor de varios fondos en un solo instrumento`,
        },
        component: () => import('@/pages/autoinvest/CategoriesPage.vue'),
    },
    {
        path: '/autoinvest/:risk?',
        name: 'autoinvest',
        meta: {
            requiresAuth: ALPHA,
            title: `AutoInvest | BTG Pactual`,
            description: `Invierte de forma automática según tu perfil y deja que nuestros expertos se encarguen. AutoInvest combina lo mejor de varios fondos en un solo instrumento`,
        },
        component: () => import('@/pages/autoinvest/IndexPage.vue'),
        props: true,
    },
    // {
    //     path: '/autoinvest',
    //     name: 'autoinvest',
    //     component: () => import('@/pages/autoinvest/IndexPage.vue'),
    // },
    {
        path: '/invertir',
        name: 'invertir',
        meta: { requiresAuth: ALPHA },
        component: () => import('@/pages/invertir/IndexPage.vue'),
    },
    {
        path: '/perfil',
        name: 'perfil',
        component: () => import('@/pages/perfil/IndexPage.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/perfil/datos',
        name: 'profile-personal',
        meta: {
            requiresAuth: true,
        },
        component: () => import('@/pages/perfil/PersonalDataPage.vue'),
    },
    {
        path: '/perfil/inversionista',
        name: 'profile-investmentprofiles',
        meta: {
            requiresAuth: true,
        },
        component: () => import('@/pages/perfil/InvestmentProfilePage.vue'),
    },
    {
        path: '/perfil/inversionista/encuesta',
        name: 'profile-investmentsurvey',
        meta: {
            requiresAuth: true,
        },
        component: () => import('@/pages/perfil/InvestmentProfileSurvey.vue'),
    },
    {
        path: '/perfil/certificados',
        name: 'profile-certificates',
        meta: {
            requiresAuth: true,
        },
        component: () => import('@/pages/perfil/CertificatesPage.vue'),
    },
    {
        path: '/perfil/cuentas',
        name: 'profile-bankaccounts',
        meta: { requiresAuth: true },
        component: () => import('@/pages/perfil/BankAccountsPage.vue'),
    },
    {
        path: '/perfil/contratos',
        name: 'profile-contracts',
        meta: {
            requiresAuth: true,
        },
        component: () => import('@/pages/perfil/ContractsPage.vue'),
    },
    {
        path: '/perfil/cartolas',
        name: 'profile-reports',
        meta: {
            requiresAuth: true,
        },
        component: () => import('@/pages/perfil/ReportsPage.vue'),
    },
    {
        path: '/perfil/cartolas/mensuales',
        name: 'profile-reports-monthly',
        meta: {
            requiresAuth: true,
        },
        component: () => import('@/pages/perfil/MonthlyReportsPage.vue'),
    },
    {
        path: '/perfil/recomienda',
        name: 'profile-share',
        meta: { requiresAuth: true },
        component: () => import('@/pages/perfil/SharePage.vue'),
    },
    {
        path: '/perfil/select-account',
        name: 'profile-selection',
        meta: { requiresAuth: true },
        component: () => import('@/pages/perfil/AccountSelectionPage.vue'),
    },
    {
        path: '/asesoria-y-contacto/select-account',
        name: 'b2b-profile-selection',
        meta: { requiresAuth: true },
        component: () =>
            import('@/pages/asesoria-y-contacto/AccountSelectionPage.vue'),
    },
    {
        path: '/perfil/soft-token',
        name: 'profile-softtoken',
        meta: { requiresAuth: true },
        component: () => import('@/pages/perfil/SoftToken.vue'),
    },
    {
        path: '/perfil/cambiar-contraseña',
        name: 'profile-change-password',
        meta: { requiresAuth: true },
        component: () => import('@/pages/perfil/ChangePassword.vue'),
    },
    {
        path: '/perfil/notificaciones',
        name: 'profile-notifications',
        meta: { requiresAuth: true },
        component: () => import('@/pages/perfil/NotificationsSettings.vue'),
    },
    {
        path: '/perfil/configuracion-cartola',
        name: 'profile-report-configuration',
        meta: { requiresAuth: true },
        component: () => import('@/pages/perfil/ReportConfiguration.vue'),
    },
    {
        path: '/perfil/soft-token',
        name: 'profile-softtoken',
        meta: { requiresAuth: true },
        component: () => import('@/pages/perfil/SoftToken.vue'),
    },
    {
        path: '/perfil/cambiar-contraseña',
        name: 'profile-change-password',
        meta: { requiresAuth: true },
        component: () => import('@/pages/perfil/ChangePassword.vue'),
    },
    {
        path: '/perfil/notificaciones',
        name: 'profile-notifications',
        meta: { requiresAuth: true },
        component: () => import('@/pages/perfil/NotificationsSettings.vue'),
    },
    {
        path: '/perfil/configuracion-cartola',
        name: 'profile-report-configuration',
        meta: { requiresAuth: true },
        component: () => import('@/pages/perfil/ReportConfiguration.vue'),
    },
    {
        path: '/mercados-en-linea',
        name: 'mercados-en-linea',
        component: () => import('@/pages/mercados-en-linea/IndexPage.vue'),
        meta: {
            requiresAuth: ALPHA,
            title: `Mercados en Línea de BTG Pactual | Invierte en tiempo real`,
            description: `Invierte y sigue los mercados con herramientas, información y gráficos en tiempo real. El monitor de mercados líder en Chile`,
        },
    },
    {
        path: '/mercados-en-linea/detalle',
        name: 'mercados-en-linea-detalle',
        component: () => import('@/pages/mercados-en-linea/DetailPage.vue'),
        meta: {
            requiresAuth: ALPHA,
            title: `Mercados en Línea de BTG Pactual | Invierte en tiempo real`,
            description: `Invierte y sigue los mercados con herramientas, información y gráficos en tiempo real. El monitor de mercados líder en Chile`,
        },
    },
    {
        path: '/bdp',
        name: 'bdp',
        component: () => import('@/pages/bdp/IndexPage.vue'),
        meta: { requiresAuth: ALPHA },
    },
    {
        path: '/transaccion-ok',
        name: 'transaccion-ok',
        redirect: { name: 'portfolio', query: { transaccion: 'ok' } },
    },
    {
        path: '/transaccion-error',
        name: 'transaccion-error',
        redirect: { name: 'portfolio', query: { transaccion: 'error' } },
    },
    {
        path: '/demo-chart',
        name: 'demo-chart',
        component: () => import('@/pages/chart/IndexPage.vue'),
    },
    {
        path: '/ficha',
        name: 'ficha-cliente',
        meta: { requiresAuth: true },
        component: () => import('@/pages/documents/FichaCliente.vue'),
    },
    {
        path: '/ncg380',
        name: 'ncg380',
        meta: { requiresAuth: true },
        component: () => import('@/pages/documents/NCG380.vue'),
    },
    //! THIS MUST BE THE LAST ONE
    {
        path: '/:catchAll(.*)*',
        name: 'not-found',
        component: () => import('@/pages/notFound/NotFound.vue'),
    },
];

export { routes };
