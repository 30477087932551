<template>
    <OrqDrawerBody>
        <template #body>
            <div class="order-completed">
                <div
                    class="order-completed__header text--emphasis-base-high-inverse"
                    :class="{ 'bg--primary-extended-90': !inverse }"
                >
                    <div class="order-completed__container">
                        <OrqColumn :gap="0">
                            <h1 class="body-baseline">
                                {{ t('transactions.success.title') }}
                            </h1>
                            <div>
                                <h2 class="heading-2xl">
                                    {{ title }}
                                    <span class="text--secondary-base-inverse">
                                        <OrqAmount
                                            :amount="amount"
                                            :format="format"
                                            vertical-align="baseline"
                                        />
                                        {{ measure }}
                                    </span>
                                </h2>
                                <div class="title-sm">
                                    {{ headline }}
                                </div>
                            </div>
                        </OrqColumn>
                    </div>
                </div>
                <div
                    class="order-completed__body"
                    :class="`text--emphasis-base-high${
                        inverse ? '-inverse' : ''
                    }`"
                >
                    <div class="order-completed__container">
                        <OrqColumn :gap="48">
                            <OrqColumn>
                                <p
                                    class="body-baseline"
                                    :class="`text--emphasis-base-medium${
                                        inverse ? '-inverse' : ''
                                    }`"
                                >
                                    {{
                                        t(
                                            !isDollar
                                                ? 'transactions.success.copyMsg'
                                                : 'transactions.success.copyMsgDollar',
                                            {
                                                email: user.email,
                                            }
                                        )
                                    }}
                                    <template v-if="outOfSchedule">
                                        <br />
                                        <br />
                                        {{
                                            t(
                                                'transactions.success.outOfScheduleMsg'
                                            )
                                        }}
                                    </template>
                                    <template v-if="cancelable">
                                        {{
                                            t(
                                                'transactions.success.bolsaNotification'
                                            )
                                        }}
                                    </template>
                                    <template v-if="!isDollar">
                                        <br />
                                        <br />
                                        {{
                                            t(
                                                'transactions.success.statusMsgSmall',
                                                {
                                                    extra: cancelable
                                                        ? t(
                                                              'transactions.success.cancelableMsg'
                                                          )
                                                        : '',
                                                }
                                            )
                                        }}
                                    </template>
                                    <span
                                        :class="`operational-link ${
                                            inverse ? 'inverse' : ''
                                        }`"
                                        :style="{
                                            marginLeft: !isDollar
                                                ? '8px'
                                                : null,
                                        }"
                                        @click.stop="onClose()"
                                    >
                                        {{
                                            t('transactions.success.linkSmall')
                                        }}
                                    </span>
                                </p>
                            </OrqColumn>
                            <OrqNotificationFixed
                                v-if="!!notification"
                                kind="info"
                                :label="notification"
                                size="compact"
                                notification-type="fixed"
                                :inverse="inverse"
                            />
                            <BodyInfo :data="info" :inverse="inverse" />
                        </OrqColumn>
                    </div>
                </div>
            </div>
        </template>
        <template #footer>
            <TransactionalFooterCompleted
                :steps="totalSteps"
                :inverse="inverse"
                @home="
                    $router.push({
                        name: 'home',
                    });
                    closeDrawer();
                "
                @accept="closeDrawer()"
            />
        </template>
    </OrqDrawerBody>
</template>

<script setup>
import {
    OrqColumn,
    OrqAmount,
    OrqNotificationFixed,
    OrqDrawerBody,
} from '@digital/orquestra';
import TransactionalFooterCompleted from '../TransactionalFooterCompleted.vue';
import BodyInfo from '../body-components/BodyInfo.vue';
import { EVENTS } from '@/consts';
import { storeToRefs } from 'pinia';

import { useUserStore } from '@/_store/user.store.js';
const userStore = useUserStore();
const { user } = storeToRefs(userStore);

import { useOrderStore } from '@/_store/order.store.js';
const orderStore = useOrderStore();
const { totalSteps } = storeToRefs(orderStore);

import { useRootEvents } from '@/composables/useRootEvents';
const rootEvents = useRootEvents();

import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
const { t } = useI18n();

const router = useRouter();

const onClose = () => {
    router.push({
        name: 'operaciones-digitales',
    });
    closeDrawer();
};

const closeDrawer = () => {
    rootEvents.emit(EVENTS.TRANSACCIONAL_CLOSE);
};

defineProps({
    title: {
        type: String,
        default: '',
    },
    amount: {
        type: Number,
        default: 0,
    },
    format: {
        type: String,
        default: 'CLP',
    },
    measure: {
        type: String,
        default: '',
    },
    headline: {
        type: String,
        default: '',
    },
    info: {
        type: Array,
        default: () => [],
    },
    outOfSchedule: {
        type: Boolean,
        default: false,
    },
    cancelable: {
        type: Boolean,
        default: false,
    },
    isDollar: {
        type: Boolean,
        default: null,
    },
    notification: {
        type: String,
        default: '',
    },
    inverse: {
        type: Boolean,
        default: false,
    },
});
</script>

<style lang="scss" scoped>
.order-completed {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 9999;

    &__container {
        max-width: 1280px;
        margin: 0 auto;
        padding: 0 48px;
    }

    &__header {
        padding: 32px 0;
    }
    &__body {
        padding: 24px 0;
    }
    &__actions {
        display: flex;
        gap: 1rem;
    }
}

.operational-link {
    text-decoration: underline;
    cursor: pointer;
    color: var(--action-secondary-enabled);

    &.inverse {
        color: var(--action-secondary-enabled-inverse);
    }
}
</style>
