import dayjs from 'dayjs';
import 'dayjs/locale/es';
import relativeTime from 'dayjs/plugin/relativeTime';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import arraySupport from 'dayjs/plugin/arraySupport';
import { i18n } from '@/i18n';
const { t } = i18n.global;

dayjs.locale('es');
dayjs.extend(relativeTime);
dayjs.extend(isToday);
dayjs.extend(isTomorrow);
dayjs.extend(arraySupport);

/**
 * @param {String} str
 * @returns String ie: thisIsMyData
 */
export const camelize = (str) => {
    return str
        .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
            return index === 0 ? word.toLowerCase() : word.toUpperCase();
        })
        .replace(/\s+/g, '')
        .replace(/-/g, '');
};
/**
 * @param {String} str
 * @returns String ie: this-is-my-data
 */
export const toKebabCase = (str) =>
    str &&
    str
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '') // Remove accents
        .replace(/([^\w]+|\s+)/g, '-') // Replace space and other characters by hyphen
        .replace(/--+/g, '-') // Replaces multiple hyphens by one hyphen
        .replace(/(^-+|-+$)/g, '')
        .toLowerCase();

/**
 * @param {Object} data
 * @returns String ie: data=test&data2=superdata
 */
export const encodeFormData = (data) => {
    // Clean empty vars
    let o = Object.fromEntries(
        // eslint-disable-next-line
        Object.entries(data).filter(([_, v]) => v != null)
    );
    return Object.keys(o)
        .map(
            (key) =>
                encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
        )
        .join('&');
};

/**
 * @param {String} str
 * @returns String ie: This Is My Data
 */
export const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
};

/**
 * @param {String} str
 * @returns String ie: thisismydata
 */
export const toSVG = (str) => {
    return str.toLowerCase().replace(/-/g, '').replace(' ', '');
};

export const formatRut = (value) => {
    const unformatted = removeRutFormat(value);
    if (unformatted.length < 2) return unformatted;
    const length = unformatted.length - 1;
    const rut = unformatted
        .substring(0, length)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    return `${rut}-${unformatted.charAt(length)}`;
};

export const removeRutFormat = (rut) => {
    var strRut = new String(rut);
    return strRut.replace(/(\.|-)/g, '');
};

const dayFormat = (date, short = false) => {
    let day = upperFirst(dayjs(date).format('dddd'));
    if (dayjs(date).isToday()) {
        day = t('date.today');
    }
    if (dayjs(date).isTomorrow()) {
        day = t('date.tomorrow');
    }
    if (short) {
        return dayjs(date).isToday()
            ? day
            : dayjs(date).format(t('date.default'));
    }
    return `${day}, ${dayjs(date).format(t('date.default'))}`;
};

const shortFromNow = (date) => {
    const now = dayjs();
    const hours = now.diff(dayjs(date), 'h');
    if (hours >= 24) {
        return dayjs(date).format(t('date.default'));
    }
    if (hours >= 1) {
        return `${hours} h`;
    }
    const minutes = now.diff(dayjs(date), 'm');
    if (minutes >= 1) {
        return `${minutes} m`;
    }
    return `${now.diff(dayjs(date), 's')} s`;
};

/**
 * This function formats a Date into a text.
 * @param {Date} date - A date to parse into a legible text.
 * @param {String} format - The format we want the output text to be.
 *     Can be 'fromNow', 'long' or 'short' with 'short' as default.
 * @returns {String} The formatted date as a string.
 */
export const formatDate = (date, format = '') => {
    const _date = Array.isArray(date)
        ? date.map((el, index) => (index === 1 ? el - 1 : el))
        : date;
    const formats = {
        fromNow: dayjs(_date).fromNow(),
        fromNowShort: shortFromNow(_date),
        medium: dayjs(_date).format(t('date.medium', 'DD [de] MMMM')),
        long: dayjs(_date).format(
            t('date.long', 'DD/MM/YYYY [a las] HH:mm [hrs.]')
        ),
        month:
            dayjs(_date).format('MMMM YYYY').charAt(0).toUpperCase() +
            dayjs(_date).format('MMMM YYYY').slice(1),
        payloads: dayjs(_date).format(t('date.payload', 'YYYY-MM-DD')),
        dayOfWeek: dayFormat(_date),
        time: dayjs(_date).format('HH:mm'),
        dayOfWeekShort: dayFormat(_date, true),
        history: dayjs(_date).isToday() ? t('date.today') : t('date.previous'),
        stringDate: dayjs(_date).format('YYYY-MM-DD'),
        today: dayjs(_date).format('[Hoy, ]DD/MM/YYYY'),
        yearMonth: dayjs(_date).format('YYYY/MM'),
    };

    if (Object.hasOwn(formats, format)) {
        return formats[format];
    } else {
        return dayjs(_date).format('DD/MM/YYYY');
    }
};

/**
 * This function formats a number into a text with a zero at left if it's positive and lower than 10.
 * @param {Int} number
 * @returns {String} The formatted number as a string.
 */
export const formatNumber = (number) => {
    if (0 <= number && number < 10) return `0${number}`;
    return `${number}`;
};

export const lowerFirst = (string) => {
    return string.charAt(0).toLowerCase() + string.slice(1);
};

export const upperFirst = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const valueOrPlaceholder = (value, placeholder = '-') => {
    if (!value) {
        return placeholder;
    }
    if (value === '') {
        return placeholder;
    }
    // Somehow we need to check string "null"
    if (value === 'null') {
        return placeholder;
    }
    return value;
};

/**
 * Description
 * Converts a phone number to the international format.
 * Doesn't work with cell phone
 *
 * @param {any} input (ie: 224905000)
 * @returns {any} (ie: +56 2 2490 5000)
 */

export const formatInternationalPhone = (input) => {
    if (input === null || input === undefined) return '-';

    // Remove non-digit characters
    let digitsOnly = input.replace(/\D/g, '');

    // Check if the number starts with a country code
    const hasCountryCode = digitsOnly.length > 0 && digitsOnly[0] === '5';

    // Add country code if missing
    if (!hasCountryCode) {
        digitsOnly = '56' + digitsOnly;
    }

    // Format the phone number
    let formattedNumber = '+';

    formattedNumber += digitsOnly.slice(0, 2) + ' ';
    digitsOnly = digitsOnly.slice(2);

    formattedNumber += digitsOnly.slice(0, 1) + ' ';
    digitsOnly = digitsOnly.slice(1);

    formattedNumber += digitsOnly.slice(0, 4) + ' ';
    digitsOnly = digitsOnly.slice(4);

    formattedNumber += digitsOnly;

    return formattedNumber;
};
