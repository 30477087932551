<template>
    <OrqToast
        v-if="visible"
        v-bind="options"
        :inverse="inverse"
        @callback="options.callback"
        @close="onClose"
    />
</template>

<script setup>
import { EVENTS } from '@/consts';
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { OrqToast } from '@digital/orquestra';

import { useRootEvents } from '@/composables/useRootEvents';
const rootEvents = useRootEvents();
const { addListener, removeListeners } = rootEvents;

const visible = ref(false);
const options = ref({});

import { useRoute } from 'vue-router';
const route = useRoute();

const inverse = computed(() =>
    ['mercados-en-linea', 'mercados-en-linea-detalle'].includes(route.name)
);

/**
 * Use useToast composable.
 *
 * rootEvents.emit(EVENTS.TOAST, {
 *  title: '',
 *  message: '',
 *  kind: 'placeholder',
 * });
 */
onMounted(() => {
    // listerner added to open intercom
    addListener(EVENTS.TOAST, (newOptions) => {
        const defaultOptions = {
            title: '',
            message: '',
            kind: 'placeholder',
            position: 'center',
            offsetTop: hasOffsetTop() ? 80 : 10,
            callback: null,
        };

        options.value = { ...defaultOptions, ...newOptions };
        visible.value = true;

        if (options.value.duration) {
            setTimeout(() => {
                visible.value = false;
            }, options.value.duration);
        }
    });

    addListener(EVENTS.TOAST_CLOSE, () => {
        visible.value = false;
    });
});

const hasOffsetTop = () => {
    return !!document.querySelector('.navbar');
};

const onClose = () => {
    visible.value = false;
};

onUnmounted(() => {
    removeListeners();
});
</script>
